.InsuranceHero {
  position: relative; }
  .InsuranceHero .isHcp-align-center {
    text-align: center;
    margin: 0 auto; }
  @media (max-width: 991.98px) {
    .InsuranceHero {
      background-image: none !important;
      margin-bottom: 50px; } }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .InsuranceHero {
      margin-bottom: 100px; } }
  @media (min-width: 992px) {
    .InsuranceHero {
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover; }
      .InsuranceHero:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        display: inline-block;
        width: 55%;
        height: 100%;
        background: url("/static/images/svg/hero-health-red.svg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: right;
        z-index: 1; }
      .InsuranceHero:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        display: inline-block;
        width: 55%;
        height: 100%;
        background: url("/static/images/svg/hero-health-blue.svg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: right;
        z-index: 2; } }
  .InsuranceHero * {
    position: relative;
    z-index: 3; }
  .InsuranceHero h1 {
    font-size: 24px; }
    @media (min-width: 992px) {
      .InsuranceHero h1 {
        font-size: 32px; } }
  .InsuranceHero--Left {
    margin: 0 -15px;
    padding: 25px; }
    @media (max-width: 991.98px) {
      .InsuranceHero--Left {
        position: relative;
        padding-bottom: 15vh; }
        .InsuranceHero--Left:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          width: 100%;
          height: 90vw;
          margin: 0 auto;
          background: url("/static/images/svg/hero-landing-mobile.svg");
          background-size: cover;
          background-repeat: no-repeat;
          background-position: top; } }
    @media (max-width: 991.98px) and (max-width: 991.98px) {
      .InsuranceHero--Left:before {
        max-height: 400px; } }
    @media (min-width: 992px) {
      .InsuranceHero--Left {
        margin: 50px 75px;
        padding: 0; } }
    .InsuranceHero--Left p {
      margin-top: 40px;
      margin-bottom: 20px; }
      @media (min-width: 992px) {
        .InsuranceHero--Left p {
          font-size: 18px; } }
  @media (max-width: 1199.98px) {
    .InsuranceHero--Right {
      margin-top: -15vh; } }
  .InsuranceHero--Center {
    margin: 0 -15px;
    padding: 25px;
    text-align: center; }
    @media (max-width: 991.98px) {
      .InsuranceHero--Center {
        position: relative; }
        .InsuranceHero--Center:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          width: 100%;
          height: 100%;
          margin: 0 auto;
          background-color: #1d66dd;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: top; } }
    @media (min-width: 992px) {
      .InsuranceHero--Center {
        margin: 50px 75px;
        padding: 0; } }
    .InsuranceHero--Center p {
      margin-top: 25px;
      margin-bottom: 20px; }
      @media (min-width: 992px) {
        .InsuranceHero--Center p {
          font-size: 18px; } }
  @media (min-width: 992px) {
    .InsuranceHero--Container {
      min-height: 480px; } }

@media (min-width: 992px) {
  .InsuranceHero-CenterBg:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    display: inline-block;
    width: 100%;
    height: 96%;
    background: url("/static/images/svg/santunan_red_hero.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 1; }
  .InsuranceHero-CenterBg:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    display: inline-block;
    width: 100%;
    height: 100%;
    background: url("/static/images/svg/santunan_blue_hero.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 2; } }
