/*
* ink colors (with variations only for black and white)
*/
.collapsible__accordion__container {
  background-color: white;
  margin-top: 12px;
  padding: 16px;
  border-radius: 8px; }
  .collapsible__accordion__container .title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px; }
    .collapsible__accordion__container .title h2 {
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
      color: #222; }
    .collapsible__accordion__container .title button {
      border: none;
      background: none;
      margin: 0;
      padding: 0; }
    .collapsible__accordion__container .title img {
      width: 24px;
      height: 24px;
      rotate: 45deg;
      transition: transform 0.5s ease-in-out; }
    .collapsible__accordion__container .title .open {
      rotate: 90deg;
      transition: transform 0.5s ease-in-out; }
  .collapsible__accordion__container .description {
    padding: 0;
    max-height: 0;
    visibility: hidden;
    transition: max-height 1s ease-out; }
    .collapsible__accordion__container .description--open {
      padding: 0 6px;
      margin-top: 10px;
      max-height: 100%;
      visibility: visible;
      transition: max-height 1s ease; }
      .collapsible__accordion__container .description--open span, .collapsible__accordion__container .description--open p, .collapsible__accordion__container .description--open ol {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: #333; }
      .collapsible__accordion__container .description--open ol {
        padding-left: 18px; }
      .collapsible__accordion__container .description--open li {
        margin-top: 16px; }
