.InsuranceModal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  z-index: 1040;
  display: none; }
  .InsuranceModal--Header {
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #dfdfdf; }
    .InsuranceModal--HeaderClose {
      text-align: center;
      width: 70px;
      background: white;
      border: none;
      color: #1d66dd; }
      .InsuranceModal--HeaderClose span {
        font-size: 22px; }
    .InsuranceModal--HeaderText {
      color: #333;
      font-size: 16px;
      font-weight: 500; }
    .InsuranceModal--HeaderReset {
      width: 70px;
      text-align: center;
      background: white;
      border: none;
      color: #1d66dd;
      font-size: 12px;
      font-weight: 500; }
  .InsuranceModal--Body {
    padding: 20px 20px; }
    @media (max-width: 1199.98px) {
      .InsuranceModal--Body {
        max-height: calc(100vh - 50px);
        overflow-x: hidden;
        overflow-y: auto; } }
    .InsuranceModal--Body .FamilySortMobile {
      position: relative; }
      .InsuranceModal--Body .FamilySortMobile i {
        display: none; }
      .InsuranceModal--Body .FamilySortMobile:first-child {
        margin-top: -1.5rem; }
    .InsuranceModal--Body .activeSort {
      color: #1d66dd;
      font-weight: 600; }
      .InsuranceModal--Body .activeSort i {
        display: block;
        position: absolute;
        top: 3px;
        right: 0;
        color: #1d66dd;
        font-size: 30px; }
  @media (max-width: 767.98px) {
    .InsuranceModal--isOpened {
      display: block; } }
  .InsuranceModal .familyCustom {
    padding: 0px; }
    .InsuranceModal .familyCustom--Individual {
      border-bottom: 1px solid #dfdfdf;
      padding-bottom: 20px;
      margin-bottom: 20px;
      position: relative; }
    .InsuranceModal .familyCustom--label {
      font-weight: bold;
      font-size: 14px;
      margin: 0px;
      margin-top: 0.5em; }
    .InsuranceModal .familyCustom--Btn-Close {
      color: #b6b6b6;
      font-size: 20px;
      position: absolute;
      top: -8px;
      right: 0;
      font-family: 'Ionicons';
      cursor: pointer; }
    .InsuranceModal .familyCustom--Action {
      position: absolute;
      right: 0;
      left: 0;
      bottom: 0; }
      .InsuranceModal .familyCustom--Action button {
        width: 100%;
        border-radius: 0px; }
    .InsuranceModal .familyCustom--Group label {
      font-weight: bold;
      margin-top: 0.5em; }
    .InsuranceModal .familyCustom--Group div {
      flex-grow: 1; }
    .InsuranceModal .familyCustom--Group input[type="number"] {
      margin-top: 0.5em; }
    .InsuranceModal .familyCustom--addItem {
      padding: 15px;
      text-align: center;
      border: 1px solid #dfdfdd;
      border-radius: 4px; }
      .InsuranceModal .familyCustom--addItem p {
        color: #1d66dd;
        font-weight: bold;
        font-size: 14px;
        margin: 0px;
        cursor: pointer; }
