/*
* ink colors (with variations only for black and white)
*/
.footer__wrapper {
  position: relative;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24); }
  @media (min-width: 768px) {
    .footer__wrapper {
      padding: 1rem !important; } }
  .footer__wrapper #footer .socmed {
    width: 22px;
    margin-right: 10px;
    opacity: 0.8; }
    .footer__wrapper #footer .socmed:hover {
      opacity: 1; }
  .footer__wrapper #footer .lifepalLogo {
    width: 36px; }
  .footer__wrapper #footer p {
    font-size: 14px;
    line-height: 22px;
    color: #333333; }
  .footer__wrapper #footer .footer__info {
    text-align: center; }
  .footer__wrapper #footer h5 {
    font-weight: bold;
    font-size: 14px;
    line-height: 1.57rem;
    margin-bottom: 10px; }
  .footer__wrapper #footer ul {
    list-style-type: none;
    padding: 0; }
    .footer__wrapper #footer ul li {
      font-size: 14px;
      line-height: 24px;
      color: #6c757d; }
      .footer__wrapper #footer ul li a {
        color: #333333; }
  .footer__wrapper #footer .input-group input {
    height: 40px;
    border-radius: 6px 0 0 6px; }
  .footer__wrapper #footer .input-group-append {
    background-color: #1d66dd;
    border-radius: 0 4px 4px 0; }
    .footer__wrapper #footer .input-group-append .btn-subscribe {
      color: white;
      border: none;
      font-size: 14px;
      font-weight: bold; }
  @media (max-width: 540px) {
    .footer__wrapper #footer .ojk-image {
      text-align: center !important; }
    .footer__wrapper #footer .payment-group {
      text-align: center !important; }
    .footer__wrapper #footer .footer__image--group {
      margin: 0 auto; } }
  .footer__wrapper #footer .image-group {
    margin-bottom: 5px; }
    .footer__wrapper #footer .image-group img {
      height: 13px;
      margin-right: 10px; }
    .footer__wrapper #footer .image-group:nth-child(2) img {
      height: 17px; }
    @media (max-width: 540px) {
      .footer__wrapper #footer .image-group img {
        height: 16px;
        margin-right: 10px; }
      .footer__wrapper #footer .image-group:nth-child(2) img {
        height: 19px; } }
  @media (min-width: 768px) {
    .footer__wrapper footer {
      padding: calc(0.75 * 1rem) !important; }
    .footer__wrapper .copy__rights {
      font-size: 12px !important; } }
