/*
* ink colors (with variations only for black and white)
*/
.profile-container {
  position: relative;
  background-color: #e4edfb; }
  .profile-container .profile-hero-wrapper {
    background-image: linear-gradient(#4380e3, #1855b8);
    display: flex;
    flex-direction: column;
    margin-bottom: 6px;
    margin-top: -8px; }
    .profile-container .profile-hero-wrapper .profile-hero-container {
      width: 1120px;
      height: 56px;
      margin: 0 auto; }
      @media (min-width: 992px) {
        .profile-container .profile-hero-wrapper .profile-hero-container {
          height: 104px; } }
      .profile-container .profile-hero-wrapper .profile-hero-container .profile-hero-title {
        color: white;
        width: 328px;
        font-size: 20px;
        margin-left: 16px;
        margin-top: 16px;
        line-height: 24px;
        font-weight: 700; }
        @media (min-width: 992px) {
          .profile-container .profile-hero-wrapper .profile-hero-container .profile-hero-title {
            width: 100%;
            font-size: 48px;
            line-height: 56px;
            margin-left: 0px;
            margin-top: 24px;
            margin-bottom: 24px; } }
  .profile-container .profile-nav-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 6px;
    padding-top: 6px; }
    .profile-container .profile-nav-wrapper .profile-nav-text {
      width: 1120px;
      margin-left: 16px; }
      @media (min-width: 992px) {
        .profile-container .profile-nav-wrapper .profile-nav-text {
          margin: 0 auto; } }

.profile-form-container {
  width: 296px;
  background-color: white;
  padding: 16px;
  border-radius: 8px;
  margin: 16px auto 16px; }
  @media (min-width: 992px) {
    .profile-form-container {
      margin: 34px auto 16px; } }
  .profile-form-container .input-phone-group {
    display: flex;
    flex-direction: row;
    border: 1px solid #d6d6d6;
    border-radius: 6px; }
  .profile-form-container .input-phone-prepend {
    background: #ccdfff;
    color: #141414;
    padding-left: 4px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    padding: 0 8px;
    width: 41px;
    font-weight: 700;
    size: 14;
    line-height: 20px;
    align-content: center; }
    @media (min-width: 992px) {
      .profile-form-container .input-phone-prepend {
        width: 41px; } }
  .profile-form-container .profile-input {
    padding: 12px;
    background-color: white;
    width: 100%;
    height: 44px;
    border: 1px solid #d6d6d6;
    border-radius: 6px;
    color: #141414;
    size: 14px;
    line-height: 20px; }
    .profile-form-container .profile-input:not(:placeholder-shown) {
      background-color: #e4edfb; }
    .profile-form-container .profile-input.blue-auto-w {
      width: 228px;
      border: none;
      border-radius: 0px; }
      @media (min-width: 992px) {
        .profile-form-container .profile-input.blue-auto-w {
          width: 238px; } }
  .profile-form-container .form-password-wrapper {
    position: relative; }
    .profile-form-container .form-password-wrapper .form-show {
      color: blue;
      position: absolute;
      right: 20px;
      top: 25%;
      cursor: pointer;
      font-size: 12px; }
  .profile-form-container .form-profile-btn {
    background-color: #ea4b4b;
    width: 100%;
    height: 40px;
    size: 16px;
    border: none;
    border-radius: 4px;
    font-weight: 700;
    line-height: 20px;
    color: white; }
