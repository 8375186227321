/*
* ink colors (with variations only for black and white)
*/
.score__card {
  max-width: 600px !important; }
  .score__card .modal-content {
    padding: 0; }
  .score__card--header {
    padding: calc(calc(0.5 * 1rem) + calc(0.5 * 1rem));
    border-bottom: 1px solid #c2c2c2; }
    .score__card--header .header__title {
      font-size: 18px;
      line-height: 22px;
      font-weight: 700;
      margin: 0 0 calc(calc(0.5 * 1rem) + calc(0.5 * 1rem)); }
    .score__card--header .header__info {
      display: flex;
      margin-bottom: calc(0.75 * 1rem); }
      @media (max-width: 991.98px) {
        .score__card--header .header__info {
          flex-direction: column; } }
      .score__card--header .header__info p {
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        margin: 0; }
        .score__card--header .header__info p span {
          font-weight: bold; }
        @media (max-width: 991.98px) {
          .score__card--header .header__info p:first-child {
            margin-bottom: calc(0.25 * 1rem); } }
        @media (min-width: 768px) {
          .score__card--header .header__info p:last-child {
            margin-left: calc(1.25 * 1rem); } }
    .score__card--header .header__button {
      border: 1px solid #1D66DD;
      color: #1D66DD; }
      .score__card--header .header__button.stop {
        border: 1px solid #E81A1A;
        color: #E81A1A; }
  .score__card--body {
    padding: calc(calc(0.5 * 1rem) + calc(0.5 * 1rem));
    height: 550px;
    overflow: auto; }
    @media (max-width: 991.98px) {
      .score__card--body {
        height: 280px; } }
  .score__card--footer {
    padding: calc(calc(0.5 * 1rem) + calc(0.5 * 1rem));
    border-top: 1px solid #c2c2c2;
    display: flex;
    justify-content: flex-end; }
    @media (max-width: 991.98px) {
      .score__card--footer {
        flex-direction: column; } }
    .score__card--footer button {
      min-width: 164px; }
      @media (max-width: 991.98px) {
        .score__card--footer button:first-child {
          margin-bottom: calc(0.5 * 1rem); } }
      .score__card--footer button:last-child {
        color: #ffffff; }
        @media (min-width: 768px) {
          .score__card--footer button:last-child {
            margin-left: calc(0.5 * 1rem); } }
  .score__card--audio {
    position: fixed;
    bottom: calc(1.25 * 1rem);
    height: calc(2 * 1rem);
    left: 40%; }
    @media (max-width: 991.98px) {
      .score__card--audio {
        left: 0;
        right: 0;
        margin: 0 auto; } }
