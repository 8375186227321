/*
* ink colors (with variations only for black and white)
*/
.FormUser {
  margin: calc(0.75 * 1rem) 0; }
  @media screen and (max-width: 768px) {
    .FormUser {
      margin: 0 0 calc(0.75 * 1rem); } }
  .FormUser .polisHolderCheckbox {
    padding: 20px 15px;
    border-radius: 4px;
    margin-bottom: 30px;
    box-shadow: 0 3px 13px -2px rgba(0, 0, 0, 0.15); }
  .FormUser .field__label {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: calc(0.5 * 1rem); }
  .FormUser .SmallMarginField {
    margin-bottom: calc(0.25 * 1rem); }
