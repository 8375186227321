/*
* ink colors (with variations only for black and white)
*/
.plan_a {
  position: fixed;
  bottom: 0;
  border-top: 1px solid #dfdfdf;
  width: 100%; }
  .plan_a .item-container-mobile {
    display: flex;
    flex-direction: column; }
    .plan_a .item-container-mobile .item-container {
      display: flex;
      flex-direction: row;
      padding: 5px 15px; }
      .plan_a .item-container-mobile .item-container .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-grow: 1;
        width: 33.333%;
        border-radius: 5px;
        border: 1px solid #dfdfdf;
        margin: 0 5px;
        padding: 5px;
        position: relative; }
        .plan_a .item-container-mobile .item-container .item i {
          line-height: 15px;
          position: absolute;
          right: 5px;
          top: 5px;
          cursor: pointer;
          background-color: #dfdfdf;
          color: white;
          border-radius: 50%;
          width: 15px;
          height: 15px;
          text-align: center;
          font-size: 16px; }
        .plan_a .item-container-mobile .item-container .item:first-of-type {
          margin-left: 0px; }
        .plan_a .item-container-mobile .item-container .item:last-of-type {
          margin-right: 0px; }
        .plan_a .item-container-mobile .item-container .item .name-plan {
          margin: 0;
          font-size: 10px;
          color: #b6b6b6;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical; }
        .plan_a .item-container-mobile .item-container .item .price-plan {
          margin: 0;
          font-size: 12px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical; }
    .plan_a .item-container-mobile .btn {
      margin: 0;
      background-color: #1d66dd;
      color: white !important;
      border: 0;
      border-radius: 0; }
  @media (max-width: 991.98px) {
    .plan_a {
      margin: 15px 0 0;
      background-color: white;
      box-shadow: 0 3px 13px -2px rgba(0, 0, 0, 0.2);
      opacity: 1;
      z-index: 1000;
      transition: opacity ease .5s;
      visibility: visible; }
      .plan_a .btn {
        background: white;
        height: 50px; } }
  .plan_a .plan-compare {
    background-color: white;
    padding: 0px 15px; }
    .plan_a .plan-compare .row {
      margin: 0; }
    .plan_a .plan-compare .item-container {
      display: flex;
      flex-direction: row;
      padding: 0; }
      .plan_a .plan-compare .item-container .item {
        flex-grow: 1;
        display: flex;
        align-items: center;
        position: relative;
        padding-right: 15px;
        width: 33.333%;
        padding: calc(0.5 * 1rem); }
        .plan_a .plan-compare .item-container .item__information {
          margin-left: calc(calc(0.5 * 1rem) + calc(0.5 * 1rem)); }
        .plan_a .plan-compare .item-container .item i {
          line-height: 15px;
          position: absolute;
          right: 20px;
          top: 5px;
          cursor: pointer;
          background-color: #dfdfdf;
          color: white;
          border-radius: 50%;
          width: 15px;
          height: 15px;
          text-align: center;
          font-size: 16px; }
        .plan_a .plan-compare .item-container .item div {
          display: flex;
          flex-direction: column;
          justify-content: space-around; }
          .plan_a .plan-compare .item-container .item div p {
            margin: 0; }
          .plan_a .plan-compare .item-container .item div .name-plan {
            color: #b6b6b6;
            font-size: 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical; }
          .plan_a .plan-compare .item-container .item div .price-plan {
            font-size: 12px; }
    .plan_a .plan-compare .button {
      padding: 0; }
      .plan_a .plan-compare .button .btn {
        background-color: #1d66dd;
        color: white;
        border: none;
        font-size: 14px;
        font-weight: 500;
        height: 100%;
        width: 100%;
        border-radius: 0; }

.plan_b {
  position: fixed;
  bottom: 0;
  right: 22px;
  margin: 16px;
  z-index: 1; }
  @media (max-width: 991.98px) {
    .plan_b {
      right: -15px;
      bottom: 90px; } }
  .plan_b .item-container-mobile {
    display: flex;
    flex-direction: column; }
    .plan_b .item-container-mobile .btn {
      margin: 0;
      background-color: #EF6767;
      color: white !important;
      border: 0;
      border-radius: 4px;
      font-weight: 700;
      display: flex;
      align-items: center;
      height: 40px;
      width: max-content; }
      .plan_b .item-container-mobile .btn div {
        border-radius: 100%;
        width: 20px;
        height: 20px;
        background-color: white;
        color: #EF6767;
        margin-left: 10px; }
      .plan_b .item-container-mobile .btn img {
        margin-left: 10px; }
