/*
* ink colors (with variations only for black and white)
*/
.ScheduledConsultation__container {
  background: linear-gradient(245.39deg, #4380E3 0%, #1855B8 50%, #134493 100%);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: space-between;
  margin-top: 16px;
  padding: 8px; }
  @media (min-width: 768px) {
    .ScheduledConsultation__container {
      align-items: center;
      flex-direction: row;
      margin-top: 12px; } }
  .ScheduledConsultation__container .mid {
    display: flex;
    gap: 12px;
    align-items: center; }
    .ScheduledConsultation__container .mid span {
      font-size: 12px;
      line-height: 16px;
      font-weight: 600;
      color: white;
      font-size: 14px;
      font-weight: 700; }
  .ScheduledConsultation__container img {
    height: 56px;
    width: 56px; }
  .ScheduledConsultation__container button {
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
    align-items: center;
    background-color: #E4EDFB;
    border-radius: 4px;
    border: none;
    color: #1D66DD;
    display: flex;
    flex-direction: row;
    height: fit-content;
    justify-content: center;
    line-height: 16px;
    max-width: 100%;
    padding: 8px 10px; }
    @media (min-width: 768px) {
      .ScheduledConsultation__container button {
        font-size: 14px;
        line-height: 18px;
        font-weight: 700;
        line-height: 16px;
        font-size: 16px;
        width: fit-content; } }
    .ScheduledConsultation__container button img {
      height: 24px;
      width: 24px;
      margin-right: 8px;
      filter: brightness(0) saturate(100%) invert(32%) sepia(61%) saturate(4279%) hue-rotate(210deg) brightness(91%) contrast(90%); }
    .ScheduledConsultation__container button span {
      width: fit-content; }
      @media (min-width: 768px) {
        .ScheduledConsultation__container button span {
          width: 172px; } }
