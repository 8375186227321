.Auto-Product--mainTitle {
  font-size: 22px;
  font-weight: bold;
  line-height: 32px;
  margin-bottom: 5px;
  text-align: center; }

.Auto-Product--title {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 25px;
  text-align: center; }

.Auto-Product--Selected {
  margin-bottom: 20px;
  border-bottom: 1px solid #1d66dd;
  display: flex;
  justify-content: center; }
  .Auto-Product--Selected label {
    font-size: 14px;
    padding-bottom: 5px;
    color: #b6b6b6;
    display: inline-block;
    width: 140px;
    text-align: center;
    margin: 0px; }
  .Auto-Product--Selectedhighlight {
    border-bottom: 5px solid #1d66dd;
    color: #1d66dd !important;
    font-weight: bold; }

.Auto-Product--items .AutoItem {
  margin-bottom: 20px;
  box-shadow: 0 3px 13px -2px rgba(0, 0, 0, 0.22);
  border-radius: 4px;
  padding: 10px;
  position: relative;
  overflow: hidden;
  color: #5e5e5e; }
  .Auto-Product--items .AutoItem--Promotion {
    position: absolute;
    height: 36px;
    line-height: 36px;
    color: white;
    text-align: center;
    width: 120px;
    top: 14px;
    right: -28px;
    transform: rotate(45deg);
    border-radius: 4px;
    font-size: 10px;
    font-weight: bold;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1); }
  .Auto-Product--items .AutoItem--Header {
    margin: 20px -10px 0;
    border-bottom: 1px solid #dfdfdf;
    display: flex;
    align-items: center;
    padding: 0 10px 10px; }
    @media (min-width: 992px) {
      .Auto-Product--items .AutoItem--Header {
        margin-top: 0; } }
    .Auto-Product--items .AutoItem--Header img {
      width: 68px;
      height: auto; }
      @media (min-width: 992px) {
        .Auto-Product--items .AutoItem--Header img {
          width: 92px; } }
    .Auto-Product--items .AutoItem--HeaderInfo {
      padding: 10px;
      font-size: 12px;
      line-height: 18px;
      font-weight: bold;
      color: #333; }
      .Auto-Product--items .AutoItem--HeaderInfoRating i {
        color: #ffc500;
        font-size: 10px;
        margin-top: 12px;
        margin-right: 3px; }
      .Auto-Product--items .AutoItem--HeaderInfoRating::after {
        content: '(5.0)';
        color: #b6b6b6;
        margin-left: 5px;
        font-size: 10px; }
      @media (min-width: 992px) {
        .Auto-Product--items .AutoItem--HeaderInfo {
          font-size: 16px;
          padding: 20px 50px; } }
  .Auto-Product--items .AutoItem--Info {
    margin: 10px -10px;
    padding: 0 10px 10px;
    font-size: 12px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px solid #dfdfdf; }
    @media (min-width: 992px) {
      .Auto-Product--items .AutoItem--Info {
        font-size: 14px;
        align-items: flex-start; } }
    .Auto-Product--items .AutoItem--InfoItem {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center; }
      @media (min-width: 992px) {
        .Auto-Product--items .AutoItem--InfoItem {
          width: auto; } }
    .Auto-Product--items .AutoItem--InfoTitle, .Auto-Product--items .AutoItem--InfoValue {
      margin-bottom: 10px;
      width: 50%; }
      @media (min-width: 992px) {
        .Auto-Product--items .AutoItem--InfoTitle, .Auto-Product--items .AutoItem--InfoValue {
          margin-bottom: 0;
          width: auto; } }
    .Auto-Product--items .AutoItem--InfoTitle {
      line-height: 30px; }
      @media (min-width: 992px) {
        .Auto-Product--items .AutoItem--InfoTitle {
          width: 200px; } }
    .Auto-Product--items .AutoItem--InfoValue {
      font-weight: bold; }
    .Auto-Product--items .AutoItem--InfoExt {
      font-weight: normal;
      font-size: 10px;
      margin-right: 5px;
      display: inline-block;
      display: none; }
      @media (min-width: 992px) {
        .Auto-Product--items .AutoItem--InfoExt {
          display: inline-block;
          font-size: 12px; } }
  .Auto-Product--items .AutoItem--Description {
    margin-left: -10px;
    margin-right: -10px;
    padding: 0 10px;
    border-bottom: 1px solid #dfdfdf; }
    .Auto-Product--items .AutoItem--Description p {
      font-size: 12px;
      color: #5e5e5e; }
    .Auto-Product--items .AutoItem--Description > ul {
      list-style: none;
      margin: 0 auto 10px;
      padding: 0;
      font-size: 12px; }
      @media (min-width: 992px) {
        .Auto-Product--items .AutoItem--Description > ul {
          display: flex;
          flex-wrap: wrap; } }
      .Auto-Product--items .AutoItem--Description > ul > li {
        display: flex;
        flex: 0 1 31%;
        margin: 0 5px; }
        .Auto-Product--items .AutoItem--Description > ul > li i {
          margin: 0px;
          font-family: "Ionicons";
          color: #ef6767;
          font-size: 22px;
          padding: 0px;
          margin-right: 12px; }
        .Auto-Product--items .AutoItem--Description > ul > li span {
          margin-top: 7px; }
  .Auto-Product--items .AutoItem--Action {
    display: flex; }
    .Auto-Product--items .AutoItem--Action .btn-primary {
      box-shadow: 0 7px 10px -5px rgba(0, 0, 0, 0.4); }
    .Auto-Product--items .AutoItem--Action button {
      font-weight: 500;
      height: 40px;
      font-size: 14px;
      width: 100%; }
      @media (min-width: 992px) {
        .Auto-Product--items .AutoItem--Action button {
          width: 460px;
          margin: 0 auto; } }
      .Auto-Product--items .AutoItem--Action button > span {
        margin-right: 5px; }
      @media (min-width: 992px) {
        .Auto-Product--items .AutoItem--Action button + button {
          margin-top: 10px; } }
      @media (min-width: 992px) {
        .Auto-Product--items .AutoItem--Action button {
          padding-left: 0;
          padding-right: 0; }
          .Auto-Product--items .AutoItem--Action button + button {
            margin-left: 10px; } }
    @media (min-width: 992px) {
      .Auto-Product--items .AutoItem--Action {
        flex-direction: row;
        margin: 15px -10px 0;
        padding-left: 10px;
        padding-right: 10px; } }
    @media (max-width: 1199.98px) {
      .Auto-Product--items .AutoItem--Action {
        flex-wrap: wrap;
        margin-top: 10px; } }
  @media (min-width: 992px) {
    .Auto-Product--items .AutoItem {
      padding: 20px; } }
