/*
* ink colors (with variations only for black and white)
*/
.button__submit {
  width: 100%;
  height: 40px;
  border-radius: calc(0.25 * 1rem);
  border: solid 1px #dfdfdf;
  background-color: #ffffff;
  color: #1D66DD;
  transition: 0.3s; }
  .button__submit:hover {
    background-color: #1D66DD;
    color: #ffffff; }

.capitalize {
  text-transform: capitalize; }
