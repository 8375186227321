@charset "UTF-8";
.InsuranceQuote {
  background: white;
  margin: 20px 10px;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 3px 13px -2px rgba(0, 0, 0, 0.22); }
  @media (min-width: 992px) {
    .InsuranceQuote {
      margin: 20px 25px; } }
  .InsuranceQuote--Form {
    padding: 20px; }
    .InsuranceQuote--Form *:not(.invalid-feedback):not(small) {
      font-size: 12px; }
    .InsuranceQuote--Form .input-group > .form-control:not(:last-child),
    .InsuranceQuote--Form .input-group > .custom-select:not(:last-child) {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px; }
    .InsuranceQuote--Form .input-group-text {
      background: #1d66dd;
      color: white;
      border: none;
      border-radius: 4px;
      padding: 0 8px;
      width: 42px;
      text-align: center; }
    .InsuranceQuote--Form .input-group.disabled .input-group-text {
      background: #b6b6b6; }
    .InsuranceQuote--Form .input-group.disabled .form-control {
      cursor: not-allowed; }
      .InsuranceQuote--Form .input-group.disabled .form-control::placeholder {
        color: #b6b6b6; }
      .InsuranceQuote--Form .input-group.disabled .form-control:hover {
        border-color: #dfdfdf; }
    .InsuranceQuote--Form .form-control {
      border: 1px solid initial;
      border-radius: 4px; }
      .InsuranceQuote--Form .form-control:not(.is-invalid) {
        border-color: #dfdfdf; }
      .InsuranceQuote--Form .form-control::placeholder {
        color: #b6b6b6; }
      .InsuranceQuote--Form .form-control:hover, .InsuranceQuote--Form .form-control:focus {
        box-shadow: none; }
        .InsuranceQuote--Form .form-control:hover:not(.is-invalid), .InsuranceQuote--Form .form-control:focus:not(.is-invalid) {
          border-color: #1d66dd; }
    .InsuranceQuote--Form button {
      font-weight: 500;
      box-shadow: 0 7px 10px -5px rgba(0, 0, 0, 0.4); }
    .InsuranceQuote--Form .form-check-input {
      display: none; }
    .InsuranceQuote--Form .form-check-label.checkbox {
      cursor: pointer; }
      .InsuranceQuote--Form .form-check-label.checkbox span:before {
        content: '\F380';
        color: #1d66dd;
        display: inline-block;
        font-family: 'Ionicons';
        margin-right: 5px; }
      .InsuranceQuote--Form .form-check-label.checkbox input[type="checkbox"]:checked + span:before {
        content: '\F2B8'; }
    .InsuranceQuote--Form .form-check-label.radio {
      cursor: pointer; }
      .InsuranceQuote--Form .form-check-label.radio span {
        transition: all ease .15s;
        color: #b6b6b6;
        display: inline-block;
        border: 1px solid #dfdfdf;
        border-radius: 17.5px;
        padding: 7px 10px; }
        @media (min-width: 992px) {
          .InsuranceQuote--Form .form-check-label.radio span {
            padding-left: 20px;
            padding-right: 20px; } }
        .InsuranceQuote--Form .form-check-label.radio span:before {
          content: '\F360';
          font-family: "Ionicons";
          display: inline-block;
          margin-right: 15px; }
      .InsuranceQuote--Form .form-check-label.radio:not(:last-child) {
        margin-right: 10px; }
      .InsuranceQuote--Form .form-check-label.radio:hover span {
        border-color: #1d66dd;
        color: #1d66dd;
        background: #e4eefd; }
        .InsuranceQuote--Form .form-check-label.radio:hover span:before {
          content: '\F361'; }
      .InsuranceQuote--Form .form-check-label.radio input:checked + span {
        color: #1d66dd;
        border-color: #1d66dd;
        background: #e4eefd; }
        .InsuranceQuote--Form .form-check-label.radio input:checked + span:before {
          content: '\F361'; }
  .InsuranceQuote--Tab {
    display: flex; }
    .InsuranceQuote--Tab button {
      flex: 1;
      background: #f7faff;
      border: none;
      padding: 10px;
      box-shadow: inset 3px -5px 7px 0 rgba(0, 0, 0, 0.07);
      color: #b6b6b6;
      font-weight: bold;
      outline: 0 !important; }
      .InsuranceQuote--Tab button.active {
        box-shadow: none;
        background: white;
        color: #1d66dd; }
      @media (max-width: 1199.98px) {
        .InsuranceQuote--Tab button {
          font-size: 12px; } }
  .InsuranceQuote--Invalid .input-group-text {
    background: #dc3545; }

.USP--Modal {
  max-width: none !important; }
  .USP--Modal .modal-content {
    border: none !important; }
    @media (min-width: 992px) {
      .USP--Modal .modal-content {
        width: 50%;
        margin: 0 auto; } }
  .USP--Modal .modal-header {
    border: none;
    padding: 20px 30px; }
  .USP--Modal h5 {
    font-size: 18px;
    font-weight: bold;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    width: 100%;
    padding-bottom: 20px;
    margin: 0; }
    @media (min-width: 992px) {
      .USP--Modal h5 {
        font-size: 22px; } }
  .USP--Modal .btn-primary {
    height: 40px;
    font-size: 14px;
    font-weight: 500; }
  .USP--Modal input {
    height: 40px; }

.USP--Form {
  padding: 0 30px 30px !important; }
