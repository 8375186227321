.span {
  color: #717171; }

.text-head {
  display: flex; }
  .text-head .lp-check_round {
    font-size: x-large;
    color: #1ec292; }
  .text-head .title {
    font-size: 18px;
    font-weight: bold; }
