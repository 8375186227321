.InsuranceModalV1 {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  right: 0;
  left: 0;
  bottom: 0;
  display: none;
  z-index: 1200;
  overflow: auto;
  padding: 50px; }
  @media (max-width: 767.98px) {
    .InsuranceModalV1 {
      padding: 0px; } }
  .InsuranceModalV1--Container {
    width: 550px;
    border-radius: 4px;
    background-color: white;
    margin: auto; }
    @media (max-width: 767.98px) {
      .InsuranceModalV1--Container {
        width: 100%; } }
  .InsuranceModalV1--Header {
    margin-bottom: 20px;
    font-size: 22px;
    font-weight: bold; }
  .InsuranceModalV1--Body {
    margin-bottom: 30px; }
  .InsuranceModalV1--isOpened {
    display: block; }
  .InsuranceModalV1 .familyCustom {
    padding: 0px; }
    .InsuranceModalV1 .familyCustom--Individual {
      border-radius: 4px;
      border: 1px solid #dfdfdf;
      padding: 20px;
      position: relative; }
    .InsuranceModalV1 .familyCustom--Btn-Close {
      color: #b6b6b6;
      font-size: 20px;
      position: absolute;
      top: 10px;
      right: 20px;
      font-family: 'Ionicons';
      cursor: pointer; }
    .InsuranceModalV1 .familyCustom--label {
      font-weight: bold;
      font-size: 14px;
      margin: 0px;
      margin-top: 0.5em; }
    .InsuranceModalV1 .familyCustom--Group {
      display: flex; }
      .InsuranceModalV1 .familyCustom--Group label {
        margin-top: 0.5em;
        font-size: 12px; }
      .InsuranceModalV1 .familyCustom--Group div {
        flex-grow: 1; }
      .InsuranceModalV1 .familyCustom--Group input[type="number"] {
        margin-top: 0.5em; }
      .InsuranceModalV1 .familyCustom--Group-second input {
        width: 80px; }
    .InsuranceModalV1 .familyCustom--Action {
      margin-top: 30px;
      border-top: 1px solid #dfdfdf;
      padding-top: 40px;
      display: flex;
      justify-content: space-evenly; }
      @media (max-width: 767.98px) {
        .InsuranceModalV1 .familyCustom--Action {
          flex-direction: column-reverse; } }
    .InsuranceModalV1 .familyCustom--Close {
      border-color: #dfdfdf; }
    .InsuranceModalV1 .familyCustom button {
      width: 220px;
      display: inline-block; }
      @media (max-width: 767.98px) {
        .InsuranceModalV1 .familyCustom button {
          margin-bottom: 10px;
          width: 100%; } }
    .InsuranceModalV1 .familyCustom--addItem {
      padding: 15px;
      text-align: center;
      border: 1px solid #dfdfdd;
      border-radius: 4px; }
      .InsuranceModalV1 .familyCustom--addItem p {
        color: #1d66dd;
        font-weight: bold;
        font-size: 14px;
        margin: 0px;
        cursor: pointer; }
    .InsuranceModalV1 .familyCustom--List > div:not(:last-child) {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0; }
    .InsuranceModalV1 .familyCustom--List > div:not(:first-child) {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-top: none; }
