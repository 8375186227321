@charset "UTF-8";
.CheckboxForm .Wrapper {
  margin: 6px 0;
  cursor: pointer; }
  .CheckboxForm .Wrapper input[type="checkbox"] {
    display: none; }

.CheckboxForm .Label {
  display: inline-block;
  vertical-align: middle;
  position: relative; }
  .CheckboxForm .Label::after {
    font-family: 'Ionicons', sans-serif;
    content: '\F2BC';
    position: absolute;
    left: 2px;
    top: -1px;
    font-size: 16px;
    color: #ffffff; }
  .CheckboxForm .Label.no-children::before {
    content: '';
    padding: 0;
    width: 16px;
    height: 16px;
    display: inline-block;
    border-radius: 2px;
    margin-right: 30px;
    vertical-align: middle;
    background-color: #dfdfdf; }
  .CheckboxForm .Label::before {
    content: '';
    padding: 0;
    width: 16px;
    height: 16px;
    display: inline-block;
    border-radius: 2px;
    margin-right: 10px;
    vertical-align: middle;
    background-color: #dfdfdf; }

.CheckboxForm input[type="checkbox"]:checked + .Label::before {
  background-color: #1d66dd; }
