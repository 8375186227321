@charset "UTF-8";
@media (max-width: 767.98px) {
  .QuoteAuto--container {
    padding: 0 10px; } }

.QuoteAuto--Form {
  box-shadow: 0 3px 13px -2px rgba(0, 0, 0, 0.22);
  padding: 30px 20px 20px; }
  .QuoteAuto--Form *:not(.invalid-feedback):not(small) {
    font-size: 14px; }
  .QuoteAuto--Form .input-group > .form-control:not(:last-child),
  .QuoteAuto--Form .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px; }
  .QuoteAuto--Form .input-group-text {
    background: #1d66dd;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 0 8px;
    width: 42px;
    text-align: center; }
  .QuoteAuto--Form .input-group.disabled .input-group-text {
    background: #b6b6b6; }
  .QuoteAuto--Form .input-group.disabled .form-control {
    cursor: not-allowed; }
    .QuoteAuto--Form .input-group.disabled .form-control::placeholder {
      color: #b6b6b6; }
    .QuoteAuto--Form .input-group.disabled .form-control:hover {
      border-color: #dfdfdf; }
  .QuoteAuto--Form .form-control {
    border: 1px solid initial;
    border-radius: 4px; }
    .QuoteAuto--Form .form-control:not(.is-invalid) {
      border-color: #dfdfdf; }
    .QuoteAuto--Form .form-control::placeholder {
      color: #b6b6b6; }
    .QuoteAuto--Form .form-control:hover, .QuoteAuto--Form .form-control:focus {
      box-shadow: none; }
      .QuoteAuto--Form .form-control:hover:not(.is-invalid), .QuoteAuto--Form .form-control:focus:not(.is-invalid) {
        border-color: #1d66dd; }
  .QuoteAuto--Form button {
    font-weight: 500;
    font-size: 14px;
    box-shadow: 0 7px 10px -5px rgba(0, 0, 0, 0.4); }
  .QuoteAuto--Form .form-check-input {
    display: none; }
  .QuoteAuto--Form .form-check-label.checkbox {
    cursor: pointer; }
    .QuoteAuto--Form .form-check-label.checkbox span:before {
      content: '\F380';
      color: #1d66dd;
      display: inline-block;
      font-family: 'Ionicons';
      margin-right: 5px; }
    .QuoteAuto--Form .form-check-label.checkbox input[type="checkbox"]:checked + span:before {
      content: '\F2B8'; }
  .QuoteAuto--Form .form-check-label.radio {
    cursor: pointer; }
    .QuoteAuto--Form .form-check-label.radio span {
      transition: all ease .15s;
      color: #b6b6b6;
      display: inline-block;
      border: 1px solid #dfdfdf;
      border-radius: 17.5px;
      padding: 7px 10px; }
      @media (min-width: 992px) {
        .QuoteAuto--Form .form-check-label.radio span {
          padding-left: 20px;
          padding-right: 20px; } }
      .QuoteAuto--Form .form-check-label.radio span:before {
        content: '\F360';
        font-family: "Ionicons";
        display: inline-block;
        margin-right: 15px; }
    .QuoteAuto--Form .form-check-label.radio:not(:last-child) {
      margin-right: 10px; }
    .QuoteAuto--Form .form-check-label.radio:hover span {
      border-color: #1d66dd;
      color: #1d66dd;
      background: #e4eefd; }
      .QuoteAuto--Form .form-check-label.radio:hover span:before {
        content: '\F361'; }
    .QuoteAuto--Form .form-check-label.radio input:checked + span {
      color: #1d66dd;
      border-color: #1d66dd;
      background: #e4eefd; }
      .QuoteAuto--Form .form-check-label.radio input:checked + span:before {
        content: '\F361'; }
