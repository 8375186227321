/*
* ink colors (with variations only for black and white)
*/
.modal__wrapper {
  width: 450px !important; }
  .modal__wrapper.bottomSheet__wrapper {
    -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both !important;
    animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both !important; }
    @media (max-width: 991.98px) {
      .modal__wrapper.bottomSheet__wrapper {
        margin: 0 !important;
        position: absolute !important;
        width: 100% !important;
        bottom: 0;
        right: 0;
        left: 0; }
        .modal__wrapper.bottomSheet__wrapper .modal-content {
          border-radius: calc(1.25 * 1rem) calc(1.25 * 1rem) 0 0;
          width: 100vw; } }
  .modal__wrapper--header {
    display: flex;
    align-items: center; }
    .modal__wrapper--header .placeholder {
      margin-left: calc(1.25 * 1rem); }
    .modal__wrapper--header .header__left {
      color: #717171; }
    .modal__wrapper--header .header__right {
      margin-left: auto; }
      .modal__wrapper--header .header__right span {
        color: #c2c2c2;
        cursor: pointer; }

@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px); }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@keyframes slide-top {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px); }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

.modal-backdrop {
  width: 100% !important;
  height: 100% !important; }
