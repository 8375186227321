/*
* ink colors (with variations only for black and white)
*/
.policy__info {
  margin-bottom: calc(0.5 * 1rem); }
  .policy__info--header {
    display: inline-flex;
    align-items: center;
    margin-bottom: 0;
    padding: calc(0.25 * 1rem) calc(0.75 * 1rem);
    padding-bottom: calc(0.5 * 1rem);
    cursor: pointer; }
    .policy__info--header .header__icon {
      color: #1D66DD;
      font-size: 18px;
      width: 3%; }
    .policy__info--header .header__title {
      margin-left: calc(1.25 * 1rem);
      color: #333333;
      text-transform: capitalize; }
    .policy__info--header .header__info {
      margin-left: auto;
      color: #333333; }
      .policy__info--header .header__info--time {
        display: block; }
        .policy__info--header .header__info--time i {
          margin-right: calc(0.5 * 1rem);
          vertical-align: middle; }
      .policy__info--header .header__info--status {
        display: flex;
        justify-content: center;
        margin-left: auto;
        margin-top: calc(0.25 * 1rem);
        border-radius: 100px;
        width: 100%;
        color: #ffffff;
        padding: 2px calc(0.75 * 1rem);
        font-size: 10px;
        line-height: 14px;
        font-weight: 400; }
    .policy__info--header.active {
      border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
    .policy__info--header.expired, .policy__info--header.renewal {
      border-radius: calc(0.25 * 1rem); }
      .policy__info--header.expired.active, .policy__info--header.renewal.active {
        border-radius: calc(0.25 * 1rem) calc(0.25 * 1rem) 0 0; }
      .policy__info--header.expired .header__icon,
      .policy__info--header.expired .header__title,
      .policy__info--header.expired .header__info, .policy__info--header.renewal .header__icon,
      .policy__info--header.renewal .header__title,
      .policy__info--header.renewal .header__info {
        color: #ffffff; }
    .policy__info--header.expired {
      background-color: #e81a1a; }
      .policy__info--header.expired .status__policy {
        color: #e81a1a; }
    .policy__info--header.renewal {
      background-color: #faaf40; }
      .policy__info--header.renewal .status__policy {
        color: #faaf40; }
  .policy__info--body {
    max-height: 0;
    padding: 0 calc(0.75 * 1rem);
    transition: max-height 0.25s ease-out, padding 0.25s ease-out;
    overflow: hidden;
    display: flex; }
    @media (max-width: 991.98px) {
      .policy__info--body {
        flex-wrap: wrap; } }
    .policy__info--body.active {
      max-height: 600px;
      padding: calc(0.75 * 1rem);
      transition: max-height 0.25s ease-in, padding 0.25s ease-in; }
    .policy__info--body .body__info {
      width: 85%; }
      @media (max-width: 991.98px) {
        .policy__info--body .body__info {
          width: 95%; } }
      .policy__info--body .body__info--policy {
        display: flex; }
        .policy__info--body .body__info--policy:not(:last-child) {
          margin-bottom: calc(0.5 * 1rem); }
        .policy__info--body .body__info--policy .policy__key {
          width: 30%; }
        .policy__info--body .body__info--policy .policy__value {
          margin-left: calc(0.5 * 1rem);
          text-transform: capitalize;
          width: 70%; }
          @media (max-width: 991.98px) {
            .policy__info--body .body__info--policy .policy__value {
              margin-left: 0;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis; } }
          .policy__info--body .body__info--policy .policy__value--discount {
            color: #858585;
            font-size: 12px;
            line-height: 16px;
            font-weight: 400; }
            .policy__info--body .body__info--policy .policy__value--discount .strikethrough {
              text-decoration: line-through; }
            .policy__info--body .body__info--policy .policy__value--discount .percent {
              color: #E81A1A; }
          .policy__info--body .body__info--policy .policy__value--documents {
            display: flex; }
            .policy__info--body .body__info--policy .policy__value--documents .documents__item {
              border-radius: calc(0.25 * 1rem);
              position: relative;
              width: 150px;
              height: 75px;
              background-color: #ebebeb;
              background-size: contain;
              background-repeat: no-repeat;
              background-position: 50% 50%;
              display: flex;
              align-items: center;
              justify-content: center; }
              .policy__info--body .body__info--policy .policy__value--documents .documents__item > span {
                font-size: 12px;
                line-height: 16px;
                font-weight: 500; }
              .policy__info--body .body__info--policy .policy__value--documents .documents__item:last-child {
                margin-left: calc(0.25 * 1rem); }
              .policy__info--body .body__info--policy .policy__value--documents .documents__item--label {
                display: flex;
                align-self: center;
                justify-content: center;
                width: 50px;
                height: 18px;
                background-color: #ffffff;
                border-radius: 10px;
                position: absolute;
                left: calc(0.25 * 1rem);
                top: calc(0.25 * 1rem);
                font-size: 12px;
                line-height: 16px;
                font-weight: 500; }
    .policy__info--body .body__action {
      display: flex;
      flex-direction: column;
      margin-left: auto; }
      @media (max-width: 991.98px) {
        .policy__info--body .body__action {
          margin-top: calc(0.75 * 1rem);
          flex-direction: row;
          margin-left: 0;
          justify-content: space-between;
          width: 100%; } }
      .policy__info--body .body__action--button {
        margin-bottom: calc(0.25 * 1rem); }
        @media (max-width: 991.98px) {
          .policy__info--body .body__action--button {
            min-width: 48%; } }
        .policy__info--body .body__action--button.whatsapp {
          display: inline-flex;
          align-items: center;
          justify-content: space-evenly;
          border: 1px solid #1ec494;
          color: #1ec494;
          background: #ffffff; }
          .policy__info--body .body__action--button.whatsapp:disabled {
            border: 1px solid #c2c2c2;
            color: #c2c2c2; }
