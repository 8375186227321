/*
* ink colors (with variations only for black and white)
*/
.card {
  background: #ffffff;
  display: flex;
  padding: calc(0.5 * 1rem) calc(1.25 * 1rem) calc(1.25 * 1rem);
  border-radius: calc(0.5 * 1rem);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
  transition: all 0.3s ease; }
  .card:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.24); }
  .card__header {
    margin-bottom: calc(0.5 * 1rem); }
  .card__body {
    height: 100%; }
