.modal-dialog {
  max-width: 600px; }

.modal-content {
  padding: 1rem; }

.modal-header {
  align-items: center; }

.modal-header h5 {
  font-size: 24px;
  font-weight: bold; }

.modal-footer {
  justify-content: center; }

.modal-footer .btn {
  height: 40px;
  width: 170px; }
