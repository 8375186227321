/*
* ink colors (with variations only for black and white)
*/
.InsuranceFilter {
  padding: 30px 20px;
  box-shadow: 0 3px 13px -2px rgba(0, 0, 0, 0.22);
  background-color: #ffffff; }
  .InsuranceFilter--HR {
    margin-left: -20px;
    margin-right: -20px; }
  .InsuranceFilter .familyGroup--Header {
    display: flex;
    justify-content: space-between; }
    .InsuranceFilter .familyGroup--Header label {
      font-weight: bold;
      font-size: 12px; }
    .InsuranceFilter .familyGroup--Header span {
      cursor: pointer;
      color: #1d66dd;
      font-size: 12px; }
  .InsuranceFilter .familyGroup--Body {
    border-radius: 4px;
    padding: 10px;
    border: 1px solid #dfdfdf;
    margin-bottom: 5px; }
    .InsuranceFilter .familyGroup--Body label {
      margin: 0px;
      display: block;
      font-weight: bold; }
    .InsuranceFilter .familyGroup--Body p {
      margin: 0px; }
  .InsuranceFilter form {
    padding: 0px; }
    @media (max-width: 767.98px) {
      .InsuranceFilter form {
        position: relative;
        height: calc(100vh - 110px);
        padding: 0px; }
        .InsuranceFilter form .last-filter {
          margin-bottom: 100px; }
        .InsuranceFilter form .buttonFilter {
          background-color: white;
          height: 80px;
          box-shadow: 0 -3px 13px -2px rgba(0, 0, 0, 0.08);
          position: fixed;
          right: 0;
          left: 0;
          bottom: -16px;
          display: flex;
          align-items: center;
          padding: 0 20px; }
          .InsuranceFilter form .buttonFilter button {
            box-shadow: 0 7px 10px -5px rgba(0, 0, 0, 0.4);
            height: 40px; } }
  @media (min-width: 992px) {
    .InsuranceFilter .buttonFilter {
      display: none; } }
  .InsuranceFilter .form-group strong {
    font-size: 12px; }
  .InsuranceFilter .form-group .containerRangeNumber {
    display: flex;
    align-items: center;
    margin-bottom: 10px; }
    .InsuranceFilter .form-group .containerRangeNumber span {
      width: 70px;
      color: #dfdfdf;
      text-align: center;
      font-size: 2em; }
    .InsuranceFilter .form-group .containerRangeNumber .rangeNumber {
      flex-grow: 3 1 3; }
  .InsuranceFilter .form-group .checkbox {
    margin: 10px 10px 10px 0;
    display: inline-block;
    cursor: pointer; }
    .InsuranceFilter .form-group .checkbox span {
      padding: 5px 20px;
      border: 1px solid #dfdfdf;
      border-radius: 17.5px; }
    .InsuranceFilter .form-group .checkbox input[type="checkbox"] {
      display: none; }
      .InsuranceFilter .form-group .checkbox input[type="checkbox"]:checked + span {
        background-color: #e4eefd;
        border-color: #1d66dd; }
  .InsuranceFilter p {
    font-size: 12px;
    color: #5e5e5e; }
  .InsuranceFilter > button {
    box-shadow: 0 7px 10px -5px rgba(0, 0, 0, 0.4); }
  @media (min-width: 992px) {
    .InsuranceFilter--Mobile {
      display: none; } }
  .InsuranceFilter--Desktop {
    display: none; }
    @media (min-width: 992px) {
      .InsuranceFilter--Desktop {
        display: block; } }

.HideWidgetMobile {
  padding: 0px; }
  @media (min-width: 992px) {
    .HideWidgetMobile {
      padding: 16px 24px; } }

.plan-specification__title h1 {
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;
  color: #333333;
  margin: 0;
  text-transform: capitalize;
  margin-bottom: calc(0.5 * 1rem); }

.plan-specification__title span {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: #b6b6b6;
  margin-bottom: calc(0.5 * 1rem);
  display: block; }

.plan-specification__button button {
  background-color: #ffffff;
  border: 1px solid #dfdfdf;
  border-radius: calc(0.25 * 1rem);
  color: #333333;
  width: 112px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500; }
