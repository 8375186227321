.search-field-root {
  position: relative;
  width: 100%; }
  .search-field-root .search-input {
    width: 100%;
    padding: 10px 42px 10px 16px;
    border-radius: 4px;
    border: 1px solid #D2DAE4;
    background: rgba(233, 240, 252, 0.04);
    outline: none;
    font-size: 14px;
    line-height: 20px; }
  .search-field-root .search-icon {
    position: absolute;
    right: 12px;
    top: 0;
    bottom: 0;
    display: flex; }
