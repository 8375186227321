.Rating-Brand i {
  color: #ffc500;
  font-size: 12px;
  margin-top: 14px;
  margin-right: 3px; }

.Rating-Brand span {
  color: #b6b6b6;
  margin-left: 5px;
  font-size: 10px; }

.Rating-Brand-Landing i {
  color: #ffc500;
  font-size: 14px;
  margin-top: 14px;
  margin-right: 3px; }

.Rating-Brand-Landing span {
  color: #b6b6b6;
  margin-left: 5px;
  font-size: 14px; }
