/*
* ink colors (with variations only for black and white)
*/
.MainItem {
  position: relative;
  overflow: hidden;
  color: #5e5e5e;
  border-radius: 8px; }
  .MainItem--Bottom {
    max-height: 0;
    transition: max-height .5s ease-in-out;
    overflow: hidden; }
    .MainItem--Bottom.show {
      max-height: 1000px;
      transition: max-height .5s ease-in-out; }
  .MainItem--divider {
    border: 1px dashed rgba(29, 102, 221, 0.1); }
  .MainItem--withBorder {
    border-radius: 8px;
    box-shadow: 2px 4px 16px 0px rgba(163, 177, 198, 0.7); }
  .MainItem--closeIcon {
    font-size: x-large;
    float: right;
    cursor: pointer; }
  .MainItem--Header {
    display: flex;
    align-items: center;
    padding: 12px 16px; }
    @media (min-width: 992px) {
      .MainItem--Header {
        margin-top: 0; } }
    .MainItem--Header img {
      height: 44px;
      object-fit: contain;
      width: 156px; }
    .MainItem--HeaderInfo {
      font-size: 14px;
      line-height: 18px;
      font-weight: bold;
      color: #333;
      width: 100%; }
      .MainItem--HeaderInfoRating i {
        color: #ffc500;
        font-size: 10px;
        margin-top: 12px;
        margin-right: 3px; }
      .MainItem--HeaderInfoRating::after {
        content: '(5.0)';
        color: #b6b6b6;
        margin-left: 5px;
        font-size: 10px; }
      @media (min-width: 992px) {
        .MainItem--HeaderInfo {
          font-size: 16px; } }
      .MainItem--HeaderInfo .proudct__usp--wrapper {
        flex-direction: row;
        display: flex;
        margin-top: 12px;
        flex-wrap: wrap;
        gap: 4px; }
        @media (min-width: 768px) {
          .MainItem--HeaderInfo .proudct__usp--wrapper {
            position: absolute;
            right: 0;
            top: 0;
            padding: 8px 6px;
            background-color: #F0F0F0;
            border-radius: 0 0 0 8px;
            margin-top: 0;
            flex-wrap: nowrap; } }
        .MainItem--HeaderInfo .proudct__usp--wrapper .product__usp--item {
          align-items: center;
          display: flex;
          flex-direction: row;
          gap: 2px;
          padding: 4px 6px;
          background-color: #F0F0F0;
          border-radius: 16px; }
          @media (min-width: 768px) {
            .MainItem--HeaderInfo .proudct__usp--wrapper .product__usp--item {
              padding: 0;
              background-color: unset;
              border-radius: 0; } }
          .MainItem--HeaderInfo .proudct__usp--wrapper .product__usp--item img {
            height: 12px;
            width: 12px; }
          .MainItem--HeaderInfo .proudct__usp--wrapper .product__usp--item span {
            font-size: 12px;
            font-weight: 400;
            margin-left: 2px; }
  .MainItem--Info {
    padding: calc(0.5 * 1rem);
    font-size: 12px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px solid #dfdfdf;
    justify-content: space-between; }
    @media (min-width: 992px) {
      .MainItem--Info {
        font-size: 14px;
        align-items: flex-start; } }
    .MainItem--InfoItem {
      width: 100%;
      display: grid;
      grid-template-columns: 0.50fr 0.50fr;
      grid-gap: 5px; }
      @media (min-width: 992px) {
        .MainItem--InfoItem {
          grid-template-columns: 0.30fr 0.30fr; } }
      .MainItem--InfoItem:not(:last-child) {
        margin-bottom: calc(0.5 * 1rem); }
    .MainItem--Info .blurred__benefit {
      opacity: 0.5; }
    .MainItem--InfoTitle {
      display: flex; }
      @media (max-width: 1199.98px) {
        .MainItem--InfoTitle {
          max-width: 180px !important; } }
      .MainItem--InfoTitle i {
        align-self: flex-start;
        font-size: 12px;
        margin-right: calc(0.5 * 1rem);
        margin-top: calc(0.25 * 1rem); }
    @media (max-width: 991.98px) {
      .MainItem--InfoTitle, .MainItem--InfoValue {
        white-space: wrap;
        align-self: flex-start; } }
    @media (min-width: 992px) {
      .MainItem--InfoTitle, .MainItem--InfoValue {
        margin-bottom: 0;
        width: auto; } }
    .MainItem--InfoTitle.capitalize, .MainItem--InfoValue.capitalize {
      text-transform: capitalize; }
    .MainItem--InfoTitle {
      align-self: flex-start; }
    .MainItem--InfoValue {
      display: flex; }
      @media (max-width: 991.98px) {
        .MainItem--InfoValue {
          flex-direction: column; } }
      .MainItem--InfoValue .info__value--price {
        display: inline-flex;
        flex-direction: column;
        font-weight: bold; }
      @media (min-width: 768px) {
        .MainItem--InfoValue .info__value--usd {
          margin-left: 16px; } }
      .MainItem--InfoValue .info__value--before {
        color: #5e5e5e;
        font-weight: normal;
        text-decoration: line-through;
        text-decoration-color: red; }
    .MainItem--InfoExt {
      font-weight: normal;
      font-size: 10px; }
      @media (min-width: 992px) {
        .MainItem--InfoExt {
          font-size: 12px; } }
  .MainItem--Description {
    padding: 0 16px; }
    .MainItem--Description p {
      font-size: 12px;
      color: #5e5e5e; }
    .MainItem--Description > ul {
      list-style: none;
      margin: 0 auto;
      padding: 0;
      font-size: 12px; }
      @media (min-width: 992px) {
        .MainItem--Description > ul {
          display: flex;
          flex-wrap: wrap; } }
      .MainItem--Description > ul > li {
        display: flex;
        flex: 0 1 32%;
        margin: 0 5px; }
  .MainItem--Action {
    padding: 12px 16px;
    text-align: center;
    display: flex;
    justify-content: space-between; }
    .MainItem--Action button {
      min-width: 49%;
      height: 42px;
      border: 1px solid rgba(29, 102, 221, 0.1);
      background-color: rgba(29, 102, 221, 0.1);
      font-weight: 700;
      font-size: 12px;
      color: #1D66DD; }
    .MainItem--Action__Container {
      display: flex;
      border-top: 0.5px solid #D6D6D6; }
      .MainItem--Action__Container button {
        align-items: center;
        background-color: #FAFAFA;
        border-bottom-left-radius: 8px;
        border-color: transparent;
        color: black;
        display: flex;
        font-size: 12px;
        font-weight: 400;
        height: 52px;
        width: 50%;
        justify-content: center; }
        @media (max-width: 991.98px) {
          .MainItem--Action__Container button {
            width: 65%; } }
        @media (max-width: 767.98px) {
          .MainItem--Action__Container button {
            width: 57.5%; } }
        .MainItem--Action__Container button i {
          margin-left: 8px; }
      .MainItem--Action__Container button.button_expand {
        background-color: #CCDFFF; }
      .MainItem--Action__Container button.active {
        background-color: #CCDFFF;
        color: #134493;
        border-bottom: none;
        font-weight: 700;
        border-radius: 0; }
        .MainItem--Action__Container button.active i {
          color: #134493;
          font-weight: 600; }
      .MainItem--Action__Container button:last-child {
        border-left: 0.5px solid #D6D6D6;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 8px; }
        @media (max-width: 991.98px) {
          .MainItem--Action__Container button:last-child {
            width: 35%; } }
        @media (max-width: 767.98px) {
          .MainItem--Action__Container button:last-child {
            width: 42.5%; } }
      .MainItem--Action__Container img {
        height: 16px;
        width: 16px;
        margin-right: 8px; }
  .MainItem--Action_compare_active {
    background-color: #F6F9FE !important;
    color: #EA4B4B !important;
    font-size: 14px !important;
    font-weight: 700 !important; }
  .MainItem--Action_planA {
    padding: 12px 16px; }
    @media (max-width: 991.98px) {
      .MainItem--Action_planA {
        display: flex;
        align-items: center; } }
    @media (min-width: 992px) {
      .MainItem--Action_planA {
        width: 85%; } }
    @media (max-width: 991.98px) {
      .MainItem--Action_planA button:last-child {
        min-width: 65px; } }
    @media (min-width: 768px) {
      .MainItem--Action_planA button:last-child {
        min-width: 120px; } }
    .MainItem--Action_planA button {
      white-space: nowrap;
      margin-right: calc(0.5 * 1rem);
      height: 32px; }
      @media (max-width: 991.98px) {
        .MainItem--Action_planA button {
          min-width: 110px; } }
    .MainItem--Action_planA button:not(:first-child) {
      background: #ffffff;
      border: 1px solid #c2c2c2 !important; }
  .MainItem--callMeButton {
    width: 100%;
    color: #1D66DD;
    border-color: transparent;
    font-size: 12px;
    font-weight: 700;
    height: 48px;
    border: 1px solid rgba(29, 102, 221, 0.1);
    background-color: rgba(29, 102, 221, 0.15); }

.coverage__list {
  max-height: 0;
  transition: max-height .5s ease-in-out;
  overflow: hidden; }
  .coverage__list li {
    margin-left: 0 !important;
    color: #333333; }
  .coverage__list li:first-child {
    margin-bottom: 8px;
    min-width: 100%; }
  .coverage__list--rows {
    display: flex;
    flex-direction: column;
    align-content: center; }
    .coverage__list--rows i {
      font-family: "Ionicons";
      color: #1ec494;
      font-size: 18px;
      padding: 0px;
      margin-right: 12px; }
    .coverage__list--rows .icon-optional {
      color: #faaf40; }

.max__coverage {
  max-height: 1500px;
  transition: max-height .5s ease-in-out; }

.load__more {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer; }
  .load__more h4 {
    color: #333333;
    margin-top: calc(0.5 * 1rem); }
  .load__more i {
    color: #1D66DD;
    font-weight: 700; }

.dropdown__icon {
  margin-left: 10px; }

.product__header--info {
  display: flex;
  align-items: center; }
  .product__header--info .product__spec--info {
    display: flex;
    margin-left: auto; }
    .product__header--info .product__spec--info span {
      align-self: center;
      white-space: nowrap; }
      .product__header--info .product__spec--info span i {
        margin-right: calc(0.5 * 1rem); }
    .product__header--info .product__spec--info .promotion__sticker {
      border-radius: 100px;
      padding: 0 calc(0.5 * 1rem);
      color: #ffffff;
      max-height: 20px;
      box-sizing: border-box;
      margin-left: 10px; }
      .product__header--info .product__spec--info .promotion__sticker span {
        margin-right: 0; }
  .product__header--info .product__logo--wrapper {
    height: 52px;
    width: auto;
    border: 0.5px solid #D6D6D6;
    border-radius: 4px;
    margin-bottom: 6px;
    padding: 4px; }

.plan__premium--details {
  color: #141414;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px; }
  .plan__premium--details .plan__premium--inline {
    display: flex;
    flex-direction: row;
    align-items: flex-start; }
  .plan__premium--details .plan__premium--amount {
    display: flex;
    flex-direction: column;
    color: #183b56;
    width: max-content; }
    .plan__premium--details .plan__premium--amount .strike__through {
      text-decoration: line-through;
      font-size: 10px;
      line-height: 14px;
      font-weight: 400;
      color: #333333; }
    .plan__premium--details .plan__premium--amount_discount {
      background-color: #F6F9FE;
      border-radius: 28px;
      border: 0.5px #CCDFFF solid;
      color: #1D66DD;
      font-size: 12px;
      font-weight: 700;
      line-height: 16px;
      margin-left: 8px;
      padding: 2px 6px;
      margin-bottom: 2px; }
  .plan__premium--details .plan__premium--price {
    align-items: flex-end;
    display: flex;
    font-size: 14px;
    font-weight: 700;
    height: 38px;
    margin-bottom: 5px;
    color: #141414; }
    .plan__premium--details .plan__premium--price span:not(:first-of-type) {
      margin-right: 4px; }
  .plan__premium--details .plan__premium--terms {
    font-size: 12px; }
  .plan__premium--details .plan__premium--price-number {
    font-size: 24px;
    margin-left: 2px;
    font-weight: 700;
    margin-bottom: 5px; }
    @media (min-width: 768px) {
      .plan__premium--details .plan__premium--price-number {
        margin-left: -5px;
        font-size: 28px; } }
  .plan__premium--details .agent__commission {
    width: 100%;
    margin-top: calc(0.5 * 1rem);
    margin-bottom: 0;
    font-size: 10px;
    line-height: 14px;
    font-weight: 400; }
    .plan__premium--details .agent__commission span {
      margin-left: calc(0.75 * 1rem); }
  .plan__premium--details .cicilan__info {
    color: #333333; }
    .plan__premium--details .cicilan__info--auto {
      color: #1D66DD; }

.other__payment--terms ul {
  padding-left: 15px; }
  .other__payment--terms ul li {
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal; }
    .other__payment--terms ul li:not(:last-child) {
      margin-bottom: calc(0.5 * 1rem); }

.other__payment--terms .divider {
  border-bottom: 1px solid #ffffff; }

.other__payment--terms .tc__wrapper .tc {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: calc(0.5 * 1rem) 0; }

.other__payment--terms .tc__wrapper .estimasi {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%; }

.box__button {
  font-weight: 700 !important;
  font-size: 12px !important;
  min-width: unset !important; }

.plan__copy--call-me {
  color: #183b56;
  margin: 4px 0 0 0;
  font-size: 12px !important;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400; }
  @media (min-width: 768px) {
    .plan__copy--call-me {
      font-size: 14px !important; } }
  .plan__copy--call-me button {
    background: none;
    border: none;
    color: #1D66DD;
    font-weight: 700;
    margin: 0;
    padding: 0;
    text-decoration: underline; }

.plan__copy--voucher-amount {
  color: #1EC494;
  font-size: 14px;
  font-weight: 700; }
