/*
* ink colors (with variations only for black and white)
*/
.rebateModal__wrapper {
  display: flex;
  margin-left: -12px; }
  @media (max-width: 991.98px) {
    .rebateModal__wrapper {
      margin-left: 0; } }
  .rebateModal__wrapper--leftCol {
    width: 70%;
    padding: 0 calc(0.75 * 1rem); }
    @media (max-width: 991.98px) {
      .rebateModal__wrapper--leftCol {
        padding: 0; } }
  .rebateModal__wrapper--rightCol {
    width: 30%; }
