/*
* ink colors (with variations only for black and white)
*/
.item__position--head {
  display: flex;
  justify-content: center; }
  @media (max-width: 991.98px) {
    .item__position--head {
      margin-bottom: calc(0.25 * 1rem); } }

.item__position--body {
  display: flex;
  justify-content: center; }
  @media (max-width: 991.98px) {
    .item__position--body {
      margin-bottom: calc(0.25 * 1rem); } }

@media (max-width: 991.98px) {
  .item__position--foot {
    margin-bottom: calc(0.25 * 1rem); } }
