/*
* ink colors (with variations only for black and white)
*/
.ProductList > ul {
  list-style: none;
  margin: 0 auto;
  padding: 0; }
  .ProductList > ul > li:not(:last-child) {
    margin-bottom: 12px; }

.ProductList--Header {
  margin-bottom: 20px; }
  @media (min-width: 992px) {
    .ProductList--Header {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      justify-content: space-between; } }

.ProductList--Loader {
  margin-top: 20px;
  text-align: center; }

.ProductList .lds-ring {
  display: inline-block;
  position: relative;
  width: 32px;
  height: 32px; }

.ProductList .lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 25.5px;
  height: 25.5px;
  margin: 3px;
  border: 3px solid #1d66dd;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #1d66dd transparent transparent transparent; }

.ProductList .lds-ring div:nth-child(1) {
  animation-delay: -0.45s; }

.ProductList .lds-ring div:nth-child(2) {
  animation-delay: -0.3s; }

.ProductList .lds-ring div:nth-child(3) {
  animation-delay: -0.15s; }

@keyframes lds-ring {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.load__more--btn {
  height: 40px;
  margin: 10px 0;
  width: 100% !important; }
  @media (max-width: 767.98px) {
    .load__more--btn {
      margin-bottom: 105px; } }
  .load__more--btn_space {
    margin-bottom: 4rem; }
    @media (max-width: 767.98px) {
      .load__more--btn_space {
        margin-bottom: 9rem; } }

@media (max-width: 991.98px) {
  .product__item--end {
    margin-bottom: 200px; } }
