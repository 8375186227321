/*
* ink colors (with variations only for black and white)
*/
.switch-bg {
  background: #0b1821; }

.switch-border {
  border: #0a2c42 solid 7px !important; }

#switch__checkbox {
  z-index: -10;
  position: absolute;
  left: 0;
  top: 0;
  display: none; }

.switch {
  position: relative;
  z-index: 99;
  overflow: hidden;
  width: 53px;
  height: 27px;
  border-radius: 45px;
  border: 2px solid #1D66DD;
  background-color: #1D66DD;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer; }
  .switch__label {
    overflow: hidden;
    z-index: 10;
    position: absolute;
    width: 100%;
    height: 100%;
    margin-bottom: 0; }
  .switch__circle {
    position: absolute;
    z-index: 10;
    width: 23px;
    height: 23px;
    background: #fff;
    border-radius: 50%;
    top: 0;
    left: 0;
    transition: all 0.3s ease-in-out; }
    #switch__checkbox:checked ~ .switch__label .switch__circle {
      transform: translateX(125px);
      background: #fff;
      left: -100px;
      background-color: #ffffff;
      border: 2px solid #1ec494; }
    .switch__circle .switch {
      background-color: #78dcbf; }

.hide--switch {
  visibility: hidden; }

.show--switch {
  visibility: visible; }

.switch__text {
  color: #b5cdf4;
  margin-left: calc(0.75 * 1rem);
  align-self: center; }

.switch-off {
  border: 2px solid #c2c2c2;
  background-color: #c2c2c2; }
  .switch-off .switch__circle {
    border: 2px solid #c2c2c2 !important; }

.switch-connected {
  border: 2px solid #78dcbf !important;
  background-color: #78dcbf !important; }
  .switch-connected .switch__circle {
    border: 2px solid #78dcbf !important; }

.switch-pause {
  border: 2px solid #c2c2c2 !important;
  background-color: #c2c2c2 !important; }
  .switch-pause .switch__circle {
    border: 2px solid #707070 !important; }

.switch-on {
  border: 2px solid #1D66DD !important;
  background-color: #1D66DD !important; }
  .switch-on .switch__circle {
    border: 2px solid #1D66DD !important; }
