/*
* ink colors (with variations only for black and white)
*/
.TableData
.Wrapper {
  border: none; }
  .TableData
.Wrapper .ActionButton {
    padding: calc(0.25 * 1rem) 0px;
    border-radius: calc(0.25 * 1rem);
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #ffffff;
    border: 1px solid #1D66DD;
    color: #1D66DD;
    justify-content: center;
    cursor: pointer; }
    .TableData
.Wrapper .ActionButton a {
      font-weight: 600; }
    .TableData
.Wrapper .ActionButton .icon {
      width: 1.5rem !important;
      margin-left: 5px !important;
      font-size: calc(1.25 * 1rem); }
  .TableData
.Wrapper .GreyColor {
    color: #b6b6b6; }
  .TableData
.Wrapper .RedColor {
    color: #E81A1A; }

.Header {
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;
  color: #333333;
  border-right: none !important;
  border-bottom: 2px solid #1D66DD;
  box-shadow: none !important;
  text-align: left; }

.Content {
  padding: calc(calc(1.25 * 1rem) + calc(0.5 * 1rem)) !important;
  color: #717171;
  align-self: center;
  text-align: left;
  border: none !important; }
  .Content:hover {
    text-overflow: initial;
    word-break: break-all;
    overflow: initial; }

.Pagination {
  margin: calc(1.25 * 1rem) auto;
  display: flex;
  justify-content: center; }
  .PaginationLink {
    padding: 6px calc(0.75 * 1rem);
    height: auto;
    border-color: transparent;
    border-radius: calc(0.25 * 1rem);
    text-decoration: none !important;
    color: #b6b6b6 !important; }
  .PaginationCurrent {
    background: transparent !important;
    border: 1px solid #333333 !important;
    color: #333333 !important; }
  .PaginationDot {
    color: #b6b6b6;
    padding: 6px calc(0.75 * 1rem); }
  .Pagination .pagination-list {
    display: flex;
    padding: 0; }
    .Pagination .pagination-list li {
      list-style-type: none; }

.badge__wrapper {
  font-weight: normal; }

.rt-tr-group {
  cursor: pointer;
  border-bottom-color: #c2c2c2 !important; }

.button__whatsapp {
  min-width: max-content !important; }
  .button__whatsapp:not(:disabled) {
    border: 1px solid #1ec494;
    color: #1ec494;
    background-color: #ffffff; }

.active__badge {
  padding: calc(0.5 * 1rem); }

.on_call {
  background-color: #00bd56 !important;
  border-color: #00bd56 !important; }

.available {
  background-color: #ffffff !important;
  border-color: #00bd56 !important; }

.table__title--custom {
  color: #1D66DD;
  text-decoration: underline;
  align-self: center;
  text-align: left;
  padding: calc(calc(1.25 * 1rem) + calc(0.5 * 1rem)) !important; }

.table__header--custom {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: #333333;
  white-space: pre-line !important; }

.table__status--header {
  padding: calc(0.5 * 1rem) calc(calc(0.5 * 1rem) + calc(0.5 * 1rem)) !important;
  display: flex;
  align-items: center; }

.table__status--body {
  padding: calc(0.5 * 1rem) calc(1.25 * 1rem) !important;
  display: flex;
  align-items: center; }

.custom__style {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  text-align: left; }
  .custom__style .ion-ios-star {
    color: #faaf40; }

.rt-table {
  background-color: #ffffff; }
  .rt-table::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #F5F5F5; }
  .rt-table::-webkit-scrollbar {
    display: block !important;
    width: 4px;
    background-color: #F5F5F5; }
  .rt-table::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #c2c2c2; }

.rt-tbody {
  overflow: visible !important;
  z-index: 1;
  max-height: 550px; }

.sticky {
  position: sticky !important;
  left: 0;
  top: 0;
  z-index: 2;
  background-color: #f2f7ff; }

.highlighted__column {
  background-color: #f2f7ff; }

.custom__audio--button button {
  min-width: 50px !important;
  width: 50px !important; }

.rt-thead {
  z-index: 3;
  position: sticky !important;
  left: 0;
  top: 0;
  background-color: #fff; }

.ReactTable .rt-resizable-header {
  padding: 6px 24px !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

.rt-resizable-header-content {
  position: relative; }

.ReactTable .rt-resizer {
  z-index: 1 !important; }

.ReactTable .rt-resizer:before {
  display: inline-block;
  position: absolute;
  right: 25px;
  top: 25%;
  height: 18px;
  width: 18px;
  color: transparent;
  content: '.';
  background-size: 12px 15px;
  background-repeat: no-repeat;
  opacity: 0.87;
  z-index: -1; }

.ReactTable .rt-resizable-header-content:after {
  position: absolute;
  right: 8px;
  top: 3px;
  height: 18px;
  width: 18px;
  z-index: 120;
  color: transparent;
  content: '.'; }

.ReactTable .rt-th .rt-resizer:before {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxOS4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9Ii01MTQgOTM2IDUwIDUwIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IC01MTQgOTM2IDUwIDUwOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+DQo8c3R5bGUgdHlwZT0idGV4dC9jc3MiPg0KCS5zdDF7ZmlsbDojYTVjMmYxO30NCgkuc3Qye2ZpbGw6I2E1YzJmMTt9DQo8L3N0eWxlPg0KPGc+DQoJPHBhdGggY2xhc3M9InN0MSIgZD0iTS01MDUuNCw5NzAuNWwxNC45LDE0LjljMC44LDAuOCwyLjIsMC44LDMsMGwxNC45LTE0LjljMS4zLTEuMywwLjQtMy42LTEuNS0zLjZILTUwNA0KCQlDLTUwNS44LDk2Ni44LTUwNi44LDk2OS4xLTUwNS40LDk3MC41eiIvPg0KPC9nPg0KPGc+DQoJPHBhdGggY2xhc3M9InN0MiIgZD0iTS01MDUuNCw5NTEuNWwxNC45LTE0LjljMC44LTAuOCwyLjItMC44LDMsMGwxNC45LDE0LjljMS4zLDEuMywwLjQsMy42LTEuNSwzLjZILTUwNA0KCQlDLTUwNS44LDk1NS4xLTUwNi44LDk1Mi44LTUwNS40LDk1MS41eiIvPg0KPC9nPg0KPC9zdmc+DQo=); }

.ReactTable .rt-th.-sort-asc .rt-resizer:before {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxOS4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9Ii01MTQgOTM2IDUwIDUwIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IC01MTQgOTM2IDUwIDUwOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+DQo8c3R5bGUgdHlwZT0idGV4dC9jc3MiPg0KCS5zdDF7ZmlsbDojMUQ2NkREO30NCgkuc3Qye2ZpbGw6IzMzMzMzMzt9DQo8L3N0eWxlPg0KPGc+DQoJPHBhdGggY2xhc3M9InN0MSIgZD0iTS01MDUuNCw5NzAuNWwxNC45LDE0LjljMC44LDAuOCwyLjIsMC44LDMsMGwxNC45LTE0LjljMS4zLTEuMywwLjQtMy42LTEuNS0zLjZILTUwNA0KCQlDLTUwNS44LDk2Ni44LTUwNi44LDk2OS4xLTUwNS40LDk3MC41eiIvPg0KPC9nPg0KPGc+DQoJPHBhdGggY2xhc3M9InN0MiIgZD0iTS01MDUuNCw5NTEuNWwxNC45LTE0LjljMC44LTAuOCwyLjItMC44LDMsMGwxNC45LDE0LjljMS4zLDEuMywwLjQsMy42LTEuNSwzLjZILTUwNA0KCQlDLTUwNS44LDk1NS4xLTUwNi44LDk1Mi44LTUwNS40LDk1MS41eiIvPg0KPC9nPg0KPC9zdmc+DQo=); }

.ReactTable .rt-th.-sort-desc .rt-resizer:before {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxOS4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9Ii01MTQgOTM2IDUwIDUwIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IC01MTQgOTM2IDUwIDUwOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+DQo8c3R5bGUgdHlwZT0idGV4dC9jc3MiPg0KCS5zdDJ7ZmlsbDojMUQ2NkREO30NCgkuc3Qxe2ZpbGw6IzMzMzMzMzt9DQo8L3N0eWxlPg0KPGc+DQoJPHBhdGggY2xhc3M9InN0MSIgZD0iTS01MDUuNCw5NzAuNWwxNC45LDE0LjljMC44LDAuOCwyLjIsMC44LDMsMGwxNC45LTE0LjljMS4zLTEuMywwLjQtMy42LTEuNS0zLjZILTUwNA0KCQlDLTUwNS44LDk2Ni44LTUwNi44LDk2OS4xLTUwNS40LDk3MC41eiIvPg0KPC9nPg0KPGc+DQoJPHBhdGggY2xhc3M9InN0MiIgZD0iTS01MDUuNCw5NTEuNWwxNC45LTE0LjljMC44LTAuOCwyLjItMC44LDMsMGwxNC45LDE0LjljMS4zLDEuMywwLjQsMy42LTEuNSwzLjZILTUwNA0KCQlDLTUwNS44LDk1NS4xLTUwNi44LDk1Mi44LTUwNS40LDk1MS41eiIvPg0KPC9nPg0KPC9zdmc+DQo=); }
