/*
* ink colors (with variations only for black and white)
*/
.quotations__modal {
  max-width: 720px !important;
  margin: 1.75rem auto; }
  .quotations__modal .card {
    padding: unset; }
  .quotations__modal--body {
    max-height: 550px;
    overflow: scroll; }
    @media (max-width: 991.98px) {
      .quotations__modal--body {
        max-height: 360px; } }
