.LabelForm .Caption,
.LabelForm .Info {
  color: #1D4476;
  font-size: 12px;
  font-weight: 700; }

.LabelForm .Info {
  font-weight: normal; }

.LabelForm .Disabled {
  color: #dfdfdf; }
