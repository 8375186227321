.CounterForm {
  margin: 0 10px;
  max-width: 130px; }
  @media (max-width: 576px) {
    .CounterForm {
      max-width: 80px; } }
  .CounterForm .Wrapper {
    display: flex;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    height: 30px;
    width: 130px; }
    @media (max-width: 991.98px) {
      .CounterForm .Wrapper {
        width: 80px; } }
  .CounterForm .Button {
    width: 24px;
    background: #ffffff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    color: #333333;
    text-align: center;
    transition: all ease .15s; }
    .CounterForm .Button > span {
      width: auto;
      height: auto; }
    .CounterForm .Button:hover {
      border-color: #1d66dd; }
  .CounterForm .Input {
    width: 65px;
    margin: 0 5px;
    background: white;
    border: none;
    border-bottom: none;
    text-align: center;
    appearance: none;
    -moz-appearance: textfield;
    font-size: 14px;
    font-weight: bold;
    color: #333333;
    transition: all ease .15s; }
    @media (max-width: 991.98px) {
      .CounterForm .Input {
        width: 15px; } }
    .CounterForm .Input::-webkit-outer-spin-button, .CounterForm .Input::-webkit-inner-spin-button {
      display: none; }
    .CounterForm .Input:hover {
      border-bottom-color: #1d66dd; }
