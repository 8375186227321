/*
* ink colors (with variations only for black and white)
*/
.text-strikethrough {
  text-decoration: line-through;
  font-size: calc(0.75 * 1rem);
  color: #dfdfdf; }

.text-error {
  font-size: calc(0.75 * 1rem);
  color: #E81A1A; }

.product__brand--title {
  font-size: 14px;
  line-height: 18px;
  font-weight: 500; }

.product__card--premium {
  display: flex; }
  .product__card--premium .premium__total {
    margin-left: auto; }
