/*
* ink colors (with variations only for black and white)
*/
.main-item-container {
  height: 0;
  transition: height ease-out; }

.main-item-detail {
  overflow: hidden;
  display: flex;
  gap: 8px;
  padding: 8px 0 12px;
  flex-direction: column; }
  @media (min-width: 768px) {
    .main-item-detail {
      gap: 16px;
      padding: 12px 0; } }
  .main-item-detail .detail-coverage {
    display: flex;
    gap: 8px;
    padding: 0 16px;
    flex-direction: column;
    color: #183B56;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    /* 133.333% */
    letter-spacing: 0.24px; }
    @media (min-width: 768px) {
      .main-item-detail .detail-coverage {
        padding: 0 20px;
        gap: 16px; } }
    .main-item-detail .detail-coverage .detail-coverage-list {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch; }
      .main-item-detail .detail-coverage .detail-coverage-list .detail-coverage-item {
        display: flex;
        align-items: flex-start;
        gap: 2px;
        align-self: stretch; }
      .main-item-detail .detail-coverage .detail-coverage-list .icon {
        font-size: 28px;
        color: #1EC494;
        font-weight: 600; }
  .main-item-detail .consult-panel {
    padding: 0 16px; }
  .main-item-detail .detail-devider {
    border: 1px dashed rgba(29, 102, 221, 0.1);
    margin: 0 16px; }
    @media (min-width: 768px) {
      .main-item-detail .detail-devider {
        margin: 0; } }
  .main-item-detail .detail-action {
    padding: 0 16px;
    display: flex;
    gap: 8px; }
    .main-item-detail .detail-action .detail-action-btn {
      align-items: center;
      background: #1D66DD;
      border-radius: 4px;
      border: 1px solid rgba(29, 102, 221, 0.1);
      color: white;
      display: flex;
      font-size: 12px;
      font-weight: 700;
      justify-content: center;
      line-height: 16px;
      min-width: unset;
      padding: 4px 0;
      text-align: center;
      width: 100%; }
    .main-item-detail .detail-action .detail-action-btn:last-child {
      background-color: white;
      border: 1px solid #1D66DD;
      color: #1D66DD; }
