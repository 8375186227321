/*
* ink colors (with variations only for black and white)
*/
.column__box {
  width: 120px;
  height: 120px;
  border: 1px dashed #dfdfdf;
  border-radius: calc(0.75 * 1rem);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: calc(0.5 * 1rem); }
  @media (max-width: 768px) {
    .column__box {
      width: 90px;
      height: 90px; } }
  .column__box--image {
    border-radius: calc(0.25 * 1rem);
    width: inherit;
    height: inherit;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    display: flex;
    justify-content: center;
    align-items: center; }
    .column__box--image .image__close {
      background: #333333;
      opacity: 0.5;
      border-radius: calc(1.25 * 1rem);
      width: calc(1.25 * 1rem);
      display: flex;
      justify-content: center;
      cursor: pointer; }
      .column__box--image .image__close i {
        color: #ffffff; }
  .column__box--content {
    height: 100%;
    width: 100%;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center; }
    .column__box--content .content__title {
      font-size: 12px;
      line-height: 16px;
      font-weight: 400;
      height: 50px;
      margin-bottom: 0; }
      @media (max-width: 768px) {
        .column__box--content .content__title {
          font-size: 11px;
          height: 40px;
          line-height: calc(0.75 * 1rem); } }
    .column__box--content .content__upload {
      font-size: 12px;
      line-height: 16px;
      font-weight: 400;
      color: #1D66DD;
      margin-bottom: 0;
      cursor: pointer;
      background: #ffffff;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0.7; }
      .column__box--content .content__upload i {
        font-size: 16px; }
