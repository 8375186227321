@charset "UTF-8";
.Quote {
  background: white;
  margin: 20px 10px;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 3px 13px -2px rgba(0, 0, 0, 0.22); }
  @media (min-width: 992px) {
    .Quote {
      margin: 20px 25px; } }
  .Quote--Content .InsuranceQuote--Form {
    box-shadow: none; }
  .Quote--Form {
    padding: 20px; }
    .Quote--Form *:not(.invalid-feedback):not(small) {
      font-size: 12px; }
    .Quote--Form .input-group > .form-control:not(:last-child),
    .Quote--Form .input-group > .custom-select:not(:last-child) {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px; }
    .Quote--Form .input-group-text {
      background: #1d66dd;
      color: white;
      border: none;
      border-radius: 4px;
      padding: 0 8px;
      width: 42px;
      text-align: center; }
    .Quote--Form .input-group.disabled .input-group-text {
      background: #b6b6b6; }
    .Quote--Form .input-group.disabled .form-control {
      cursor: not-allowed; }
      .Quote--Form .input-group.disabled .form-control::placeholder {
        color: #b6b6b6; }
      .Quote--Form .input-group.disabled .form-control:hover {
        border-color: #dfdfdf; }
    .Quote--Form .form-control {
      border: 1px solid initial;
      border-radius: 4px; }
      .Quote--Form .form-control:not(.is-invalid) {
        border-color: #dfdfdf; }
      .Quote--Form .form-control::placeholder {
        color: #b6b6b6; }
      .Quote--Form .form-control:hover, .Quote--Form .form-control:focus {
        box-shadow: none; }
        .Quote--Form .form-control:hover:not(.is-invalid), .Quote--Form .form-control:focus:not(.is-invalid) {
          border-color: #1d66dd; }
    .Quote--Form button {
      font-weight: 500;
      box-shadow: 0 7px 10px -5px rgba(0, 0, 0, 0.4); }
    .Quote--Form .form-check-input {
      display: none; }
    .Quote--Form .form-check-label.checkbox {
      cursor: pointer; }
      .Quote--Form .form-check-label.checkbox span:before {
        content: '\F380';
        color: #1d66dd;
        display: inline-block;
        font-family: 'Ionicons';
        margin-right: 5px; }
      .Quote--Form .form-check-label.checkbox input[type="checkbox"]:checked + span:before {
        content: '\F2B8'; }
    .Quote--Form .form-check-label.radio {
      cursor: pointer; }
      .Quote--Form .form-check-label.radio span {
        transition: all ease .15s;
        color: #b6b6b6;
        display: inline-block;
        border: 1px solid #dfdfdf;
        border-radius: 17.5px;
        padding: 7px 10px; }
        @media (min-width: 992px) {
          .Quote--Form .form-check-label.radio span {
            padding-left: 20px;
            padding-right: 20px; } }
        .Quote--Form .form-check-label.radio span:before {
          content: '\F360';
          font-family: "Ionicons";
          display: inline-block;
          margin-right: 15px; }
      .Quote--Form .form-check-label.radio:not(:last-child) {
        margin-right: 10px; }
      .Quote--Form .form-check-label.radio:hover span {
        border-color: #1d66dd;
        color: #1d66dd;
        background: #e4eefd; }
        .Quote--Form .form-check-label.radio:hover span:before {
          content: '\F361'; }
      .Quote--Form .form-check-label.radio input:checked + span {
        color: #1d66dd;
        border-color: #1d66dd;
        background: #e4eefd; }
        .Quote--Form .form-check-label.radio input:checked + span:before {
          content: '\F361'; }
  .Quote--Tab {
    display: flex; }
    .Quote--Tab button {
      flex: 1;
      background: #f7faff;
      border: none;
      padding: 10px;
      box-shadow: inset 3px -5px 7px 0 rgba(0, 0, 0, 0.07);
      color: #b6b6b6;
      font-weight: bold;
      outline: 0 !important; }
      .Quote--Tab button.active {
        box-shadow: none;
        background: white;
        color: #1d66dd; }
      @media (max-width: 1199.98px) {
        .Quote--Tab button {
          font-size: 12px; } }
  .Quote--Invalid .input-group-text {
    background: #dc3545; }
