/*
* ink colors (with variations only for black and white)
*/
/*
* ink colors (with variations only for black and white)
*/
.btn {
  cursor: pointer;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 14px; }
  .btn-outline-white {
    color: white;
    border-color: white; }
    .btn-outline-white:hover, .btn-outline-white:focus, .btn-outline-white:active {
      background: white !important;
      border-color: transparent !important;
      color: #329ef7 !important; }
    .btn-outline-white:focus, .btn-outline-white:active {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.25) !important; }
  .btn-outline-default {
    color: #1d66dd;
    font-weight: 500;
    font-size: 14px;
    border: 1px solid #dfdfdf; }
    .btn-outline-default.selected, .btn-outline-default:hover, .btn-outline-default:focus {
      border-color: #1d66dd;
      color: #1d66dd;
      transition: all ease .5s; }
  .btn-white {
    background: white;
    color: #1d66dd;
    font-weight: 500; }
    .btn-white:hover {
      color: #1d66dd; }
  .btn-subscribe {
    color: #6c757d;
    border-width: 1px;
    border-color: #6c757d;
    box-shadow: none !important; }
    .btn-subscribe:hover, .btn-subscribe:active, .btn-subscribe:focus {
      background-color: #6c757d !important;
      color: white !important;
      border-color: transparent !important; }
  .btn-lg {
    font-size: 1em; }

.button-other-premium {
  width: 25%; }
  @media (max-width: 991.98px) {
    .button-other-premium {
      width: 40%; } }
  @media (max-width: 767.98px) {
    .button-other-premium {
      width: 50%; } }

.btn-file {
  position: relative;
  overflow: hidden;
  border: dashed 1px;
  width: 100%;
  padding: 10px;
  cursor: pointer;
  margin-bottom: 10px;
  background: aliceblue; }

.btn-file input[type='file'] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block; }

.button-primary, .button-default, .button-secondary, .button-faded-orange, .call__list--modal .lp-button-ghost {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 calc(0.75 * 1rem);
  min-width: fit-content;
  width: auto;
  border-color: transparent;
  cursor: pointer;
  border-radius: 4px;
  border-style: solid; }

.button-primary {
  color: #ffffff;
  background-color: #1D66DD; }
  .button-primary:focus {
    color: #ffffff;
    outline: none;
    box-shadow: 0 4px 8px 0 currentColor; }
  .button-primary:hover {
    color: #ffffff;
    opacity: 0.9;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24); }
  .button-primary:disabled {
    opacity: 0.5;
    cursor: not-allowed; }

.button-default {
  color: rgba(51, 51, 51, 0.64);
  background-color: #ffffff; }
  .button-default:focus {
    color: rgba(51, 51, 51, 0.64);
    outline: none;
    box-shadow: 0 4px 8px 0 currentColor; }
  .button-default:hover {
    color: rgba(51, 51, 51, 0.64);
    opacity: 0.9;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24); }
  .button-default:disabled {
    opacity: 0.5;
    cursor: not-allowed; }

.button-secondary {
  color: rgba(51, 51, 51, 0.64);
  background-color: #f95151; }
  .button-secondary:focus {
    color: rgba(51, 51, 51, 0.64);
    outline: none;
    box-shadow: 0 4px 8px 0 currentColor; }
  .button-secondary:hover {
    color: rgba(51, 51, 51, 0.64);
    opacity: 0.9;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24); }
  .button-secondary:disabled {
    opacity: 0.5;
    cursor: not-allowed; }

.button-faded-orange {
  color: #ffffff;
  background-color: #ef6767; }
  .button-faded-orange:focus {
    color: #ffffff;
    outline: none;
    box-shadow: 0 4px 8px 0 currentColor; }
  .button-faded-orange:hover {
    color: #ffffff;
    opacity: 0.9;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24); }
  .button-faded-orange:disabled {
    opacity: 0.5;
    cursor: not-allowed; }

.button-sm {
  height: calc(1.5 * 1rem); }

.button-md {
  height: calc(2 * 1rem); }

.button-lg {
  height: calc(2.5 * 1rem); }

.call__list--modal .modal-content, .call__list--modal .modal-header {
  padding: calc(0.75 * 1rem); }

.call__list--modal .Header {
  padding: calc(1.25 * 1rem) calc(0.75 * 1rem) !important; }

.call__list--modal .modal-body {
  padding: 0; }

.call__list--modal .audio__controls--wrapper {
  position: fixed;
  height: 50px;
  bottom: 20px;
  width: 470px;
  max-width: 470px; }
  @media (max-width: 991.98px) {
    .call__list--modal .audio__controls--wrapper {
      left: 2px; } }
  .call__list--modal .audio__controls--wrapper audio {
    height: 100%;
    width: 100%; }

.call__list--modal .lp-button-ghost {
  background-color: #ffffff;
  border: 1px solid #1D66DD;
  color: #1D66DD; }
  .call__list--modal .lp-button-ghost:focus {
    outline: none;
    box-shadow: 0 4px 8px 0 currentColor; }
  .call__list--modal .lp-button-ghost:hover {
    opacity: 0.9;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24); }
  .call__list--modal .lp-button-ghost:disabled {
    opacity: 0.5;
    cursor: not-allowed; }
  .call__list--modal .lp-button-ghost:hover {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.24);
    border-color: #1D66DD; }
  .call__list--modal .lp-button-ghost:focus {
    border-color: #164ba2;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.24); }
  .call__list--modal .lp-button-ghost:disabled {
    opacity: 0.5;
    pointer-events: none; }
