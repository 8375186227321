.More-Benefit {
  margin-top: 50px; }
  .More-Benefit--mainTitle {
    font-size: 22px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 5px; }
  .More-Benefit--title {
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 15px; }
  .More-Benefit--items {
    display: flex;
    justify-content: center;
    flex-wrap: wrap; }
  .More-Benefit--item {
    padding: 20px 30px;
    flex: 1 1 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center; }
    @media (max-width: 991.98px) {
      .More-Benefit--item {
        padding: 20px 0;
        flex: 1 1 100%; } }
    .More-Benefit--item div {
      width: 150px;
      height: 150px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 15px; }
      .More-Benefit--item div img {
        max-height: 100%;
        width: auto; }
    .More-Benefit--item strong {
      font-size: 14px;
      font-weight: bold; }
    .More-Benefit--item p {
      margin-top: 10px;
      font-size: 14px;
      font-weight: 500; }
