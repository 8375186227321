.CheckboxForm .Wrapper {
  padding: 0;
  list-style-type: none;
  display: flex;
  align-items: center; }
  .CheckboxForm .Wrapper li:not(:last-child) {
    margin-right: 1rem; }

.CheckboxForm .Input {
  display: none; }
  .CheckboxForm .InputWrapper {
    background: #ffffff;
    color: #b6b6b6;
    border: 1px solid #dfdfdf;
    border-radius: 17.5px;
    padding: 4px 15px;
    cursor: pointer;
    transition: all ease .15s; }
    .CheckboxForm .InputWrapper .IconOff {
      display: inline-block; }
    .CheckboxForm .InputWrapper:hover {
      color: #1d66dd;
      border-color: #1d66dd;
      background: #e4eefd; }
      .CheckboxForm .InputWrapper:hover .IconOn {
        display: inline-block; }
      .CheckboxForm .InputWrapper:hover .IconOff {
        display: none; }
  .CheckboxForm .Input:checked + .InputWrapper {
    color: #1d66dd;
    border-color: #1d66dd;
    background: #e4eefd; }
    .CheckboxForm .Input:checked + .InputWrapper .IconOn {
      display: inline-block; }
    .CheckboxForm .Input:checked + .InputWrapper .IconOff {
      display: none; }
  .CheckboxForm .Input:disabled + .InputWrapper {
    cursor: not-allowed;
    background: #ffffff;
    color: #b6b6b6;
    border-color: #dfdfdf; }
    .CheckboxForm .Input:disabled + .InputWrapper:hover {
      background: #ffffff;
      color: #b6b6b6;
      border-color: #dfdfdf; }

.CheckboxForm .Icon {
  width: 1.2rem; }
  .CheckboxForm .IconOn {
    display: none; }
  .CheckboxForm .IconOff {
    display: none; }

.CheckboxForm .Help {
  font-size: 12px; }
