/*
* ink colors (with variations only for black and white)
*/
.reschedule__lead {
  max-width: 500px !important; }

.callBackForm .row {
  margin-bottom: calc(0.75 * 1rem); }
  .callBackForm .row .InputForm input {
    width: 100% !important; }

.callBackForm hr {
  margin: 2rem 0; }

.callBackForm p {
  font-weight: 500; }

.callBackForm .react-datepicker-wrapper {
  visibility: hidden;
  margin-top: -10px; }

.callBackForm .react-datepicker-popper {
  z-index: 2;
  margin-top: -10px; }

.callBackForm .react-datepicker-popper {
  top: -20px !important; }

.callBackForm .agreement {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1.5rem; }
  .callBackForm .agreement .text {
    margin: 0;
    font-weight: normal; }
    .callBackForm .agreement .text span {
      font-weight: bold; }

.callBackForm .error-validation p {
  margin: 0;
  text-align: center; }

.callBackForm .btnSubmit {
  height: 40px;
  width: 170px;
  border: 1px solid #dfdfdf;
  background-color: #1d66dd;
  color: #ffffff; }
  .callBackForm .btnSubmit:active, .callBackForm .btnSubmit:focus {
    background-color: #1d66dd !important; }

.callBackForm .btnBack {
  height: 40px;
  width: 170px;
  border: 1px solid #dfdfdf;
  background-color: #ffffff;
  color: #1d66dd; }
  .callBackForm .btnBack:active, .callBackForm .btnBack:focus {
    background-color: #ffffff !important; }

.callBackForm .btn:active, .callBackForm .btn:focus {
  outline: none !important;
  box-shadow: none !important;
  border: 1px solid #dfdfdf !important; }
