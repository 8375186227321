.Hero {
  position: relative; }
  .Hero--Row {
    min-height: 400px; }
  @media (max-width: 767.98px) {
    .Hero--No-Height {
      min-height: 0px; } }
  @media (max-width: 991.98px) {
    .Hero {
      background-image: none !important; } }
  @media (min-width: 992px) {
    .Hero {
      background-repeat: no-repeat;
      background-position: right;
      background-size: contain; }
      .Hero:before {
        content: '';
        position: absolute;
        top: 0;
        left: 10px;
        display: inline-block;
        width: 55%;
        height: 100%;
        background: url("/static/images/svg/hero-red.svg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: right;
        z-index: 1; }
      .Hero:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        display: inline-block;
        width: 55%;
        height: 100%;
        background: url("/static/images/svg/hero-blue.svg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: right;
        z-index: 2; } }
  @media (min-width: 1367px) {
    .Hero {
      background-size: cover; } }
  .Hero * {
    position: relative;
    z-index: 3; }
  .Hero h1 {
    font-size: 24px; }
    @media (min-width: 992px) {
      .Hero h1 {
        font-size: 32px; } }
  .Hero--Right .Quote--Form,
  .Hero--Right .InsuranceQuote--Form {
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 3px 13px -2px rgba(0, 0, 0, 0.22); }
  .Hero--Left {
    margin: 0 -15px;
    padding: 25px; }
    @media (max-width: 991.98px) {
      .Hero--Left {
        position: relative; }
        .Hero--Left:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 150%;
          background: url("/static/images/svg/hero-landing-mobile-blue.svg");
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center; }
        .Hero--Left:before {
          content: '';
          position: absolute;
          top: 25px;
          left: 0;
          width: 100%;
          height: 150%;
          background: url("/static/images/svg/hero-landing-mobile-red.svg");
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center; } }
    @media (max-width: 767.98px) {
      .Hero--Left:after, .Hero--Left:before {
        height: 100%; } }
    @media (min-width: 992px) {
      .Hero--Left {
        margin: 50px 75px;
        padding: 0; } }
    @media (min-width: 576px) {
      .Hero--Left .btn {
        width: 380px; } }
    @media (max-width: 767.98px) {
      .Hero--Left .btn {
        width: 100%; } }
    .Hero--Left p {
      margin-top: 40px;
      margin-bottom: 20px; }
      @media (min-width: 992px) {
        .Hero--Left p {
          font-size: 18px; } }
    @media (min-width: 992px) {
      .Hero--Left .large32px {
        font-size: 32px;
        line-height: 42px;
        margin-top: 0px; } }
    @media (max-width: 767.98px) {
      .Hero--Left .large32px {
        font-size: 22px;
        line-height: 32px;
        margin-top: 0px; } }

@media (max-width: 1199.98px) {
  .syariahLeftBg:before {
    width: 50%; }
  .syariahLeftBg:after {
    width: 50%; } }
