.FloatingButton {
  background: white;
  border-bottom: 1px solid rgba(29, 102, 221, 0.1);
  border-top: 1px solid rgba(29, 102, 221, 0.1);
  bottom: 22px;
  display: flex;
  height: 48px;
  left: 0;
  margin: 0;
  opacity: 0;
  padding: 10px 0;
  position: fixed;
  top: 50px;
  transition: opacity ease .5s;
  visibility: hidden;
  width: 100%;
  z-index: 1020; }
  .FloatingButton--hasCompare {
    bottom: 210px; }
  .FloatingButton--Opened {
    opacity: 1;
    visibility: visible; }
  .FloatingButton--Closed {
    visibility: hidden; }
  .FloatingButton button {
    flex: 1;
    background: none;
    border: none;
    font-size: 12px;
    font-weight: 500;
    color: #333;
    padding: 0px 10px; }
    .FloatingButton button * {
      vertical-align: middle; }
    .FloatingButton button:not(:last-child) {
      border-right: 1px solid #D6D6D6; }
  @media (min-width: 992px) {
    .FloatingButton {
      display: none; } }
  .FloatingButton_callme {
    padding: 10px 16px;
    position: fixed;
    top: 100px;
    width: 100%;
    z-index: 1020; }
    .FloatingButton_callme button {
      width: 100%;
      height: 48px;
      border-radius: 4px;
      border: none;
      padding: 8px 12px;
      background-color: #FEF0F0;
      text-align: left; }
      .FloatingButton_callme button span {
        font-weight: 600;
        font-size: 14px;
        line-height: 32px;
        color: rgba(239, 103, 103, 0.8); }
      .FloatingButton_callme button img {
        float: right; }
    @media (min-width: 992px) {
      .FloatingButton_callme {
        display: none; } }

.unset-top {
  top: unset; }

.floating__button--group {
  display: none; }
  @media (max-width: 991.98px) {
    .floating__button--group {
      display: block; } }
