@media (min-width: 992px) {
  .Brand {
    text-align: center;
    padding-top: 50px;
    max-width: 75%;
    margin: 0 auto; } }

.Brand--title {
  font-size: 22px;
  font-weight: bold; }
