/*
* ink colors (with variations only for black and white)
*/
.user__info--container {
  font-family: 'Open Sans';
  width: 100%;
  padding: 16px;
  background: linear-gradient(180deg, #4380E3 0%, #1D66DD 48.5%, #1855B8 100%); }
  @media (min-width: 576px) {
    .user__info--container {
      padding: 32px 0; } }
  .user__info--container .user__info--title-box {
    max-width: 1120px;
    margin: 0 auto;
    padding: 0; }
    .user__info--container .user__info--title-box h2 {
      font-size: 24px;
      color: white;
      font-weight: 700;
      line-height: 28px;
      margin: 0; }
      @media (min-width: 576px) {
        .user__info--container .user__info--title-box h2 {
          font-size: 48px;
          line-height: 56px; } }
