@charset "UTF-8";
.isFlexRow {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  align-items: center;
  flex-direction: row; }

.dropdownSort {
  margin-bottom: 20px; }
  .dropdownSort--lists {
    position: relative;
    width: 100%;
    margin-top: 10px; }
    .dropdownSort--lists::after {
      content: '\F3D0';
      font-family: 'Ionicons';
      font-size: 20px;
      color: #1d66dd;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin-left: auto;
      width: 13px;
      display: inline-block;
      margin-right: 10px;
      line-height: 40px;
      cursor: pointer; }
    .dropdownSort--lists select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      padding-right: 30px;
      color: #333;
      font-size: 14px;
      font-weight: bold;
      cursor: pointer;
      font-size: 12px;
      width: auto;
      margin-top: 0; }
    .dropdownSort--lists--lists {
      margin-top: 0; }
  .dropdownSort .dropdown button {
    text-align: left; }
  .dropdownSort .dropdown .dropdown-toggle,
  .dropdownSort .dropdown .dropdown-menu {
    width: 100%; }
    .dropdownSort .dropdown .dropdown-toggle div,
    .dropdownSort .dropdown .dropdown-menu div {
      padding: 7px; }
      .dropdownSort .dropdown .dropdown-toggle div:hover,
      .dropdownSort .dropdown .dropdown-menu div:hover {
        background-color: #1d66dd;
        color: white; }
  .dropdownSort p {
    margin: 0 15px 0 0; }
  .dropdownSort--list {
    display: none; }
  .dropdownSort--selected {
    height: 40px;
    width: 232px;
    border: 1px solid #dfdfdf; }
