input[type='text'],
input[type='number'],
textarea {
  font-size: 16px !important; }

.InputForm .Wrapper {
  padding-left: 10px;
  width: 100%;
  height: 40px;
  border-radius: 4px;
  transition: all ease .15s;
  box-shadow: none; }
  .InputForm .Wrapper[disabled] {
    background: #f7faff;
    border-color: #dfdfdf;
    color: #333333; }

.InputForm input {
  border: 1px solid #dbdbdb; }

.InputForm .Help {
  font-size: 12px; }

.InputForm .Phone::before, .InputForm .Currency::before {
  content: attr(data-text);
  width: 56px;
  height: 40px;
  font-weight: 700;
  color: #183B56;
  line-height: 40px;
  display: inline-block;
  background: rgba(29, 102, 221, 0.1);
  text-align: center;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px; }

.InputForm .PhoneInput, .InputForm .CurrencyInput {
  display: inline-block !important;
  width: calc(100% - 56px);
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.InputForm .PhoneError::before, .InputForm .CurrencyError::before {
  background: #ef6767; }

.InputForm .Search::before {
  content: '\E920';
  font-size: 16px;
  border: 1px solid #dbdbdb;
  border-right: 0px;
  font-family: 'lifepal-icon';
  width: 40px;
  height: 40px;
  color: #4b87e0;
  line-height: 35px;
  display: inline-block;
  background: #ffffff;
  text-align: center;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px; }

.InputForm .SearchInput {
  display: inline-block !important;
  width: calc(100% - 40px);
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }
  .InputForm .SearchInput:active, .InputForm .SearchInput:focus {
    outline: none; }

.InputForm .SearchError::before {
  background: #ef6767; }

.InputForm .TextArea {
  border-radius: 4px;
  padding: 10px;
  width: 100%;
  min-height: 100px;
  transition: all ease .15s;
  box-shadow: none; }
  .InputForm .TextArea[disabled] {
    background: #dfdfdf;
    border-color: transparent; }
