/*
* ink colors (with variations only for black and white)
*/
.InputQuotationForm {
  border-radius: 4px;
  box-shadow: 0 2px 6px -2px rgba(0, 0, 0, 0.3);
  background-color: white;
  height: 50px;
  padding: calc(calc(0.5 * 1rem) + calc(0.5 * 1rem));
  display: flex;
  flex-direction: row;
  align-items: center; }
  .InputQuotationForm.member__disabled {
    background: #ebebeb; }
  .InputQuotationForm--title {
    flex-grow: 2;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    color: #707070;
    cursor: pointer; }
  .InputQuotationForm .CounterForm {
    flex-grow: 1; }
  .InputQuotationForm .CheckBoxForm {
    flex-grow: 1; }
  .InputQuotationForm .css-1jgn1w8-control {
    border: none;
    outline: none; }
  .InputQuotationForm .DropdownForm {
    min-width: 120px; }
  .InputQuotationForm .css-1li0unn-control {
    border: none !important; }
