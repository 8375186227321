/*
* ink colors (with variations only for black and white)
*/
.background {
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeHolderShimmer;
  -webkit-animation-timing-function: linear;
  background: #f6f7f9;
  background-image: linear-gradient(to right, #f6f7f9 0%, #e9ebee 20%, #f6f7f9 40%, #f6f7f9 100%);
  background-repeat: no-repeat;
  background-size: 800px 104px;
  height: 104px;
  position: relative; }

.shimmer {
  padding: 12px;
  max-width: 100%;
  height: 150px;
  margin: 4rem auto;
  background-color: #fff;
  border: 1px solid;
  border-color: #e5e6e9 #dfe0e4 #d0d1d5;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24); }

#shimmer_0 {
  margin-top: 12px; }

.background div {
  background: #fff;
  height: 6px;
  left: 0;
  position: absolute;
  right: 0; }

div.a {
  height: 40px;
  left: 40px;
  right: auto;
  top: 0;
  width: 8px; }

div.b {
  height: 8px;
  left: 48px;
  top: 0; }

div.c {
  left: 136px;
  top: 8px; }

div.d {
  height: 12px;
  left: 48px;
  top: 14px; }

div.e {
  left: 100px;
  top: 26px; }

div.f {
  height: 10px;
  left: 48px;
  top: 32px; }

div.g {
  height: 20px;
  top: 40px; }

div.h {
  left: 410px;
  top: 60px; }

div.i {
  height: 13px;
  top: 66px; }

div.j {
  left: 440px;
  top: 79px; }

div.k {
  height: 13px;
  top: 85px; }

div.l {
  left: 178px;
  top: 98px; }

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0; }
  100% {
    background-position: 468px 0; } }
