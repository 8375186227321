.DropdownFormTransparent .Wrapper {
  position: relative;
  z-index: 5; }
  .DropdownFormTransparent .Wrapper * {
    font-family: 'Montserrat', sans-serif !important; }
  .DropdownFormTransparent .Wrapper select {
    height: 40px;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    padding: 10px;
    width: 100%;
    appearance: none;
    color: #b6b6b6;
    cursor: pointer;
    transition: all ease .15s; }
    .DropdownFormTransparent .Wrapper select:hover {
      color: #1d66dd; }
  .DropdownFormTransparent .Wrapper > i {
    position: absolute;
    top: 10px;
    bottom: 0;
    right: 10px;
    color: #1d66dd;
    cursor: pointer; }
  .DropdownFormTransparent .Wrapper [class*="-control"] > div:nth-child(2) :nth-child(1) {
    display: none; }
  .DropdownFormTransparent .Wrapper [class*="-control"] > div:nth-child(2) :nth-child(2)::before {
    content: '\E903';
    font-family: 'lifepal-icon';
    color: #ffffff;
    font-weight: 700;
    position: relative; }
  .DropdownFormTransparent .Wrapper [class*="-singleValue"], .DropdownFormTransparent .Wrapper [class*="-placeholder"] {
    color: #ffffff;
    font-weight: 500; }

.DropdownFormTransparent .ReactSelect {
  cursor: pointer !important; }

.DropdownFormTransparent .Help {
  font-size: 12px; }

.DropdownFormTransparent .HideArrow [class*="-control"] > div:nth-child(2) {
  display: none; }
