.InputQuotationLife {
  width: 100%;
  position: relative; }
  .InputQuotationLife .form-control.is-invalid {
    background-image: none; }
  .InputQuotationLife .ion-md-close {
    position: absolute;
    right: 0;
    top: 5px;
    font-size: 18px;
    color: #b6b6b6; }
  .InputQuotationLife .form-control {
    border: none;
    border-radius: 0px;
    border-bottom: 1px solid #dfdfdf;
    text-align: center; }
    .InputQuotationLife .form-control:focus {
      box-shadow: none; }
    .InputQuotationLife .form-control::placeholder {
      font-size: 22px;
      color: #b6b6b6;
      font-weight: 500;
      text-align: center; }
    @media (max-width: 767.98px) {
      .InputQuotationLife .form-control::placeholder {
        text-align: left; } }
