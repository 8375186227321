/*
* ink colors (with variations only for black and white)
*/
.d-inline-block {
  display: inline-block; }

.d-inline {
  display: inline;
  font-size: 14px !important;
  color: #1D66DD !important; }

.nav-item-offer {
  color: #1D66DD !important; }

.third__level {
  padding-left: 0;
  list-style: none;
  max-height: 500px;
  z-index: 1050;
  overflow-y: scroll; }

#second__level {
  max-height: calc(100vh - 220px);
  overflow-y: scroll; }

.level__4 {
  font-size: 12px;
  font-weight: 500 !important;
  padding-bottom: 12px !important; }
  .level__4 .dropdown-link {
    font-size: 12px;
    font-weight: 500 !important;
    padding-bottom: 10px !important; }
  .level__4 .dropdown-link:hover {
    color: #1D66DD !important; }

.profile__dropwdown {
  position: absolute;
  top: 100%;
  right: calc(calc(0.75 * 1rem) + calc(0.25 * 1rem));
  min-width: 150px;
  border-radius: calc(0.25 * 1rem);
  background: #ffffff;
  padding: calc(calc(0.75 * 1rem) + calc(0.25 * 1rem));
  border: solid 0.5px #c2c2c2; }
  .profile__dropwdown p {
    color: #717171; }
    .profile__dropwdown p:last-child {
      margin-bottom: 0px; }

.mobile__navigation {
  z-index: 99999999 !important; }

.initial__name {
  height: calc(calc(1.25 * 1rem) + calc(0.5 * 1rem));
  width: calc(calc(1.25 * 1rem) + calc(0.5 * 1rem));
  border-radius: calc(calc(1.25 * 1rem) + calc(0.5 * 1rem));
  background: #1D66DD;
  color: #ffffff;
  font-size: calc(0.75 * 1rem);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  align-self: center; }

.next__level {
  cursor: pointer; }

.focused__search {
  display: inline-flex;
  width: 140px;
  border: 1px solid #fff;
  border-radius: 16px;
  background: #ffffff; }
  .focused__search i {
    margin-right: calc(0.5 * 1rem); }

.navbar.mobileNavBgColor {
  padding: 0 1rem !important;
  background-color: #ffffff; }
  .navbar.mobileNavBgColor.agentColor {
    background-color: #1d66dd; }

.navbar .color-white {
  color: #fff !important; }

.navbar .mobileNav .left {
  z-index: 3; }

.navbar .mobileNav i {
  font-size: 25px;
  cursor: pointer;
  color: #000;
  width: 20px; }
  .navbar .mobileNav i.agentColor {
    color: #73a9ea !important; }

.navbar .mobileNav .center-item {
  flex-grow: 1;
  color: #000;
  margin-left: 30px;
  text-align: center; }

.navbar .mobileNav .center-item:last-child {
  margin-left: -40px;
  margin-right: auto;
  color: #000;
  z-index: 2; }

.navbar .mobileNav .right {
  margin-left: 0 !important;
  color: #000; }

.navbar .mobileNav p {
  margin: 0.5rem; }
  .navbar .mobileNav p.agentColor {
    color: #ffffff; }

@media (min-width: 992px) {
  .navbar {
    padding: 0 !important; } }

.navbar .overlay {
  top: 0;
  left: 0;
  position: absolute;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1031; }

@media (max-width: 991.98px) {
  .navbar {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 10;
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.06);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 0px 1rem;
    background-color: #ffffff; } }

.navbar .navbar-brand {
  padding: 20px 30px; }
  @media (max-width: 991.98px) {
    .navbar .navbar-brand {
      display: inline-block;
      margin-right: 0;
      font-size: 1.25rem;
      line-height: inherit;
      white-space: nowrap;
      padding: 0px; } }

.navbar .navbar-collapse {
  position: relative;
  height: 75px;
  margin-top: 0;
  align-items: flex-start !important; }
  .navbar .navbar-collapse .navButton {
    position: absolute;
    left: 15px;
    color: #dfdfdf;
    font-size: 28px;
    height: 0px;
    top: 15px;
    cursor: pointer;
    color: #717171; }
  @media (max-width: 991.98px) {
    .navbar .navbar-collapse {
      position: absolute;
      width: 80vw;
      height: 100vh !important;
      background-color: white;
      top: 0;
      left: 0;
      z-index: 1032; } }

.navbar .mobileSearchForm.open {
  position: fixed;
  top: 0;
  left: 0;
  height: 50px;
  width: 100%;
  background-color: white;
  transition: all 0.3s ease-in-out; }
  .navbar .mobileSearchForm.open .search__box {
    width: 100%; }
    .navbar .mobileSearchForm.open .search__box i {
      font-size: 14px;
      top: 15px; }
  .navbar .mobileSearchForm.open form {
    display: flex;
    align-items: center;
    padding: calc(0.25 * 1rem); }
    .navbar .mobileSearchForm.open form i {
      margin-right: 15px;
      font-size: 28px; }
    .navbar .mobileSearchForm.open form input {
      width: 100%;
      padding-top: 8px;
      padding-bottom: 5px;
      border-radius: 24px !important; }
      .navbar .mobileSearchForm.open form input:focus {
        outline: none !important;
        box-shadow: none !important;
        border: none !important;
        border-bottom: 1px solid #dfdfdf !important; }

.navbar .mobileSearchForm.close {
  transition: all 0.3s ease-in-out;
  display: none; }

.navbar .menuNavigationMobile {
  height: 100%;
  position: relative; }
  .navbar .menuNavigationMobile .sticky__whatsapp {
    position: absolute;
    bottom: 5vh;
    z-index: 1051;
    width: 100%;
    cursor: pointer; }
    .navbar .menuNavigationMobile .sticky__whatsapp span {
      font-size: 14px;
      color: #333;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal; }
    .navbar .menuNavigationMobile .sticky__whatsapp p {
      margin-top: 8px;
      display: flex;
      color: #333333; }
      .navbar .menuNavigationMobile .sticky__whatsapp p img {
        align-self: center;
        margin-right: 8px; }
  .navbar .menuNavigationMobile .nav-item .dropdownParentTitle {
    color: #333333;
    text-decoration: none;
    cursor: pointer;
    font-size: 12px;
    font-weight: 500; }
  .navbar .menuNavigationMobile .nav-item .dropdownParentToggle {
    display: inline; }
  .navbar .menuNavigationMobile .nav-item a.nav-link {
    font-size: 12px;
    position: relative; }
    .navbar .menuNavigationMobile .nav-item a.nav-link > i.icon {
      color: #1d66dd;
      font-size: 1.7em;
      height: 0;
      margin-right: 20px; }
  .navbar .menuNavigationMobile .nav-item .dropdown-menu {
    margin: 0px;
    border-radius: 0px;
    border-bottom: 1px solid #dee2e6;
    padding-left: 1.5rem; }
    .navbar .menuNavigationMobile .nav-item .dropdown-menu:hover {
      border-right: 5px solid #1d66dd; }
    .navbar .menuNavigationMobile .nav-item .dropdown-menu .dropdown-item {
      padding: 0.25rem 0;
      font-size: 12px;
      font-weight: normal; }
  .navbar .menuNavigationMobile .nav-item .show {
    display: flex;
    flex-direction: row;
    align-items: center; }
  .navbar .menuNavigationMobile .nav-item .dropdown-menu:last-child {
    border: none; }
  .navbar .menuNavigationMobile .pull-left {
    float: left; }
  .navbar .menuNavigationMobile .pull-right {
    float: right; }
  .navbar .menuNavigationMobile .navItemNested {
    position: absolute;
    width: 80vw;
    height: 100vh;
    background-color: white;
    top: -48px;
    left: -40px;
    z-index: 1050;
    padding: 0 0 10px 40px;
    overflow: auto; }
    .navbar .menuNavigationMobile .navItemNested .nav-item:hover {
      border-right: 5px solid #1d66dd; }

.navbar .menuNavigationDesktop {
  width: 100%;
  border: 1px solid #dfdfdf;
  border-top: none;
  border-radius: 0 0 4px 4px;
  height: 35px;
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  padding: 0 10px; }
  .navbar .menuNavigationDesktop a {
    cursor: pointer; }
  .navbar .menuNavigationDesktop .searchForm {
    padding: 9px !important;
    margin-right: -10px;
    border-right: 1px solid #dfdfdf;
    border-left: 1px solid #dfdfdf;
    margin-top: -3px; }
    .navbar .menuNavigationDesktop .searchForm i {
      color: #1d66dd;
      font-size: 16px;
      margin-right: 10px !important; }
    .navbar .menuNavigationDesktop .searchForm input {
      border: none;
      width: 250px; }
      .navbar .menuNavigationDesktop .searchForm input:focus {
        outline: none !important;
        box-shadow: none !important;
        border: none !important; }
  .navbar .menuNavigationDesktop .nav-item {
    border-bottom: none;
    padding: 0 10px; }
    .navbar .menuNavigationDesktop .nav-item i.icon,
    .navbar .menuNavigationDesktop .nav-item i.fa {
      margin-right: 5px; }
    .navbar .menuNavigationDesktop .nav-item i.icon.ion-ios-calculator {
      font-size: 14px; }
    .navbar .menuNavigationDesktop .nav-item a.nav-link {
      padding: 8px 0 8px;
      font-size: 12px;
      font-weight: 500; }
  .navbar .menuNavigationDesktop .pull-left {
    float: left; }
  .navbar .menuNavigationDesktop .pull-right {
    float: right;
    height: 32px; }
  .navbar .menuNavigationDesktop .navbar-nav-submenu {
    display: none;
    position: absolute;
    top: 37px;
    left: 0;
    padding: 0 10px;
    width: 100%; }
    .navbar .menuNavigationDesktop .navbar-nav-submenu .nav-item {
      border-bottom: none;
      padding: 0 10px;
      list-style: none; }
      .navbar .menuNavigationDesktop .navbar-nav-submenu .nav-item span.nav-link {
        padding: 8px 0 8px !important;
        font-size: 12px;
        font-weight: 500; }
      .navbar .menuNavigationDesktop .navbar-nav-submenu .nav-item a.nav-link {
        padding: 8px 0 8px !important;
        font-size: 12px;
        font-weight: 500; }
        .navbar .menuNavigationDesktop .navbar-nav-submenu .nav-item a.nav-link.nav-item-offer {
          color: #1D66DD !important; }
      .navbar .menuNavigationDesktop .navbar-nav-submenu .nav-item.nav-item-btn-primary, .navbar .menuNavigationDesktop .navbar-nav-submenu .nav-item.nav-item-phone, .navbar .menuNavigationDesktop .navbar-nav-submenu .nav-item.nav-item-outline {
        display: list-item; }
    .navbar .menuNavigationDesktop .navbar-nav-submenu .nav-item-offer a.nav-link {
      color: #1d66dd; }
  .navbar .menuNavigationDesktop .dropdown-menu.hide,
  .navbar .menuNavigationDesktop .navbar-nav-submenu.hide {
    display: none !important; }
  .navbar .menuNavigationDesktop .dropdown-menu.show,
  .navbar .menuNavigationDesktop .navbar-nav-submenu.show {
    display: block !important; }
  .navbar .menuNavigationDesktop div.dropdown-menu {
    width: 197.92px; }
  .navbar .menuNavigationDesktop .dropdown-menu.show {
    display: block !important; }
  .navbar .menuNavigationDesktop .dropdown-menu.flex-show {
    display: flex !important;
    flex-direction: row; }
    .navbar .menuNavigationDesktop .dropdown-menu.flex-show li:not(:last-child) {
      margin-right: calc(0.75 * 1rem); }
  .navbar .menuNavigationDesktop .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 10px;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 250px;
    padding: .5rem 0;
    margin: .125rem 0 0;
    font-size: .875rem;
    color: #212529;
    text-align: left;
    width: max-content;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: .25rem;
    position: absolute;
    float: none;
    display: block;
    box-shadow: 0 3px 13px -2px rgba(0, 0, 0, 0.22);
    border-radius: 4px;
    margin: 0 0 0 -10px;
    padding: 20px 10px 5px;
    border: 1px solid #dfdfdf; }
    .navbar .menuNavigationDesktop .dropdown-menu .level__4 {
      padding-bottom: 5px !important; }
    .navbar .menuNavigationDesktop .dropdown-menu .dropdown-item {
      display: block;
      width: 100%;
      padding: .25rem .6rem;
      clear: both;
      font-weight: 400;
      color: #212529;
      text-align: inherit;
      white-space: nowrap;
      background-color: transparent;
      border: 0;
      list-style: none;
      padding-bottom: 15px; }
      .navbar .menuNavigationDesktop .dropdown-menu .dropdown-item .level__3--title {
        color: #333333;
        font-size: 12px;
        font-weight: 700; }
      .navbar .menuNavigationDesktop .dropdown-menu .dropdown-item .level__3--item {
        color: #333333;
        font-size: 12px;
        font-weight: 500; }
      .navbar .menuNavigationDesktop .dropdown-menu .dropdown-item .level__4--items {
        padding-left: 0; }
        .navbar .menuNavigationDesktop .dropdown-menu .dropdown-item .level__4--items li {
          padding: 0;
          padding-left: 0;
          padding-bottom: 12px; }
          .navbar .menuNavigationDesktop .dropdown-menu .dropdown-item .level__4--items li:first-child {
            margin-top: calc(0.75 * 1rem); }
      .navbar .menuNavigationDesktop .dropdown-menu .dropdown-item a.dropdown-link {
        color: #1d66de;
        text-decoration: none;
        background-color: transparent;
        color: #333333; }
    .navbar .menuNavigationDesktop .dropdown-menu .dropdown-block {
      display: block;
      margin-bottom: 20px; }
      .navbar .menuNavigationDesktop .dropdown-menu .dropdown-block p.dropdown-title {
        display: block;
        font-weight: bold;
        margin-bottom: 0.3rem; }
      .navbar .menuNavigationDesktop .dropdown-menu .dropdown-block p > span {
        display: block;
        width: 100%; }
