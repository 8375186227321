/*
* ink colors (with variations only for black and white)
*/
.rating__reason {
  text-align: start;
  padding: calc(calc(0.5 * 1rem) + calc(0.5 * 1rem)) 0; }
  .rating__reason--question {
    font-weight: bold;
    margin-bottom: calc(0.5 * 1rem); }
  .rating__reason--star {
    display: flex;
    align-items: center;
    margin-bottom: calc(0.75 * 1rem); }
    .rating__reason--star i {
      font-size: 24px;
      margin-right: calc(0.5 * 1rem);
      color: #c2c2c2; }
      .rating__reason--star i.active {
        color: #faaf40; }
      .rating__reason--star i.clickable {
        cursor: pointer; }
  .rating__reason--field textarea::placeholder {
    color: #c2c2c2; }
