/*
* ink colors (with variations only for black and white)
*/
.checkout__box {
  width: 100%;
  bottom: 0;
  background: #fff;
  border-radius: calc(0.5 * 1rem);
  padding: 20px;
  margin: 0 auto; }
  @media (max-width: 991.98px) {
    .checkout__box {
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
      position: fixed;
      border-top-right-radius: calc(0.75 * 1rem);
      border-top-left-radius: calc(0.75 * 1rem);
      z-index: 10; } }
  .checkout__box--info {
    margin-bottom: 4px;
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: space-between; }
  .checkout__box--buttons {
    display: flex;
    width: 100%; }
    .checkout__box--buttons button {
      width: 10%;
      min-width: min-content !important; }
      .checkout__box--buttons button:only-child {
        width: 100%; }
    .checkout__box--buttons button:not(:only-child):last-child {
      width: 90%;
      margin-left: 4px; }
