/*
* ink colors (with variations only for black and white)
*/
footer .footer__form--group {
  display: flex;
  align-items: center;
  border-top: 1px solid #dfdfdf;
  padding: 1.5rem 0;
  border-bottom: none; }
  @media (min-width: 768px) {
    footer .footer__form--group {
      justify-content: space-between; } }
  @media (min-width: 320px) and (max-width: 767px) {
    footer .footer__form--group {
      flex-direction: column; } }
  @media (min-width: 768px) {
    footer .footer__form--group .footer__form--title {
      width: 50%; } }
  footer .footer__form--group .footer__form--title p {
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    margin: 0; }
  footer .footer__form--group .footer__form {
    align-self: flex-start; }
    @media (max-width: 500px) {
      footer .footer__form--group .footer__form {
        width: 100%;
        margin-top: calc(0.5 * 1rem); } }
    @media (min-width: 768px) {
      footer .footer__form--group .footer__form {
        width: 50%; } }
  footer .footer__form--group .footer__form--item {
    display: flex;
    position: relative;
    width: 100%;
    height: 40px; }
    footer .footer__form--group .footer__form--item input {
      border-radius: calc(0.25 * 1rem) 0 0 calc(0.25 * 1rem);
      border: 1px solid #dfdfdf;
      flex: 1 1 auto;
      padding: calc(0.25 * 1rem) calc(0.75 * 1rem); }
      footer .footer__form--group .footer__form--item input:focus {
        outline: none;
        border: 1px solid #1D66DD;
        border-right: none; }
    footer .footer__form--group .footer__form--item button {
      color: #ffffff;
      position: relative;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      height: 40px;
      min-width: 100px !important;
      max-width: 100px !important; }

footer .footer__insurance {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #dfdfdf;
  padding: calc(2 * 1rem) 0; }
  footer .footer__insurance--item {
    width: 25%; }
    @media (max-width: 768px) {
      footer .footer__insurance--item {
        width: 50%; } }

footer .footer__item .company__name {
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;
  color: #444;
  margin-top: 0;
  margin-bottom: calc(0.5 * 1rem); }

footer .footer__item .company__info {
  width: 85%; }
  footer .footer__item .company__info .company__address {
    font-size: 12px;
    line-height: 16px;
    font-weight: 500; }

footer .footer__item ul {
  padding: 0;
  margin-top: 0;
  margin-bottom: 0; }
  footer .footer__item ul li {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    list-style: none; }
    footer .footer__item ul li:last-child {
      margin-bottom: calc(0.5 * 1rem); }
    footer .footer__item ul li a {
      color: #6c757d;
      text-decoration: none; }

footer .footer__item--group {
  display: grid;
  padding: calc(2 * 1rem) 0;
  grid-template-columns: 1fr 1fr; }
  @media (min-width: 768px) {
    footer .footer__item--group {
      grid-template-columns: .8fr .6fr .35fr .35fr .9fr; } }

footer .footer__image--group {
  width: 100%; }

@media (max-width: 500px) {
  footer .grid__one {
    grid-column: 1/3;
    margin-bottom: calc(2 * 1rem); } }

@media (max-width: 500px) {
  footer .grid__four {
    grid-column: 1/4;
    margin-bottom: calc(2 * 1rem);
    margin-top: 0; } }

@media (max-width: 500px) {
  footer .grid__five {
    grid-column: 1/4;
    margin-top: calc(2 * 1rem); } }

footer .footer__info {
  border-top: 1px solid #dfdfdf;
  padding: 1.5rem 0; }
  footer .footer__info .copy__rights {
    line-height: 20px;
    font-weight: 400;
    display: block;
    margin: 0; }
